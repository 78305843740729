import React from "react";
import useTranslationDashboard from "../useTranslationDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useQueryRecruiterAlertsSearch from "../search/useQueryRecruiterAlertsSearch";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./DashboardAlertsList.scss";
import WaitForData from "../../common/data-fetching/WaitForData";
import DashboardAlertsListItems from "./DashboardAlertsListItems";
import Loader from "../../common/loading-widgets/Loader";

export default function DashboardAlertsList(props) {
  const { recruiterId } = props;
  const ALERTS_LIST_LENGTH = 10;
  const ALERTS_LIST_STATUS = "NOT_VIEWED";
  const ALERTS_LIST_SORT_TYPE = "DATE_ASC";

  const { data, loading, errors } = useQueryRecruiterAlertsSearch(
    ALERTS_LIST_LENGTH,
    { recruiterId: recruiterId, status: ALERTS_LIST_STATUS },
    ALERTS_LIST_SORT_TYPE
  );

  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "DashboardAlertsList");

  const listTitle = t("dashboard:recruiters_dashboard_alerts_list_label");

  return (
    <Loader>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          data["nodes"].length > 0 && (
            <div className="DashboardAlertsList spaced-children widget-block">
              <Row>
                <Col className={"title-col"}>
                  <h2>{listTitle}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DashboardAlertsListItems items={data["nodes"]} />
                </Col>
              </Row>
            </div>
          )
        )}
      />
    </Loader>
  );
}
