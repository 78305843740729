import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryApplicationByCandidateIdCount from "./useQueryApplicationByCandidateIdCount";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../utils/StringUtils";

export default function JobTitleItem(props) {
  const { candidateId } = props;
  const { t, loading: tLoading } = useTranslationCandidates();

  useShowLoader(tLoading, "ApplicationCountItem");
  const { data: count, loading, errors } = useQueryApplicationByCandidateIdCount(
    candidateId
  );

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <span>
            {t("candidates:candidates_application_count", {
              count: StringUtils.nullToZero(count),
            })}
          </span>
        )}
      />
    </HorizontalLoader>
  );
}
