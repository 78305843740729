import React from "react";
import useFirebase from "../infra-no-ui/firebase/useFirebase";
import Firebase from "../infra-no-ui/firebase/Firebase";
import useTranslationAuthenticationNoUi from "./useTranslationAuthenticationNoUi";

export default function useResetPassword() {

  // Send new password callback
  const resetPassword = (email) => {
    setLoading(true);
    setError(null);

    // Ask Firebase to send an email to reset password
    firebase
      .doPasswordReset(email.trim())
      .catch(error => {
        const message = t(Firebase.getErrorTranslation(t, error.code)) + " (code: " + error.code + ")";
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {t, loading: tLoading} = useTranslationAuthenticationNoUi();
  const firebase = useFirebase();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  return ({
    resetPassword,
    loading: loading || tLoading,
    error}
  );
}
