import "./QuoteSection.scss";
import { Row, Col, Image } from "react-bootstrap";
import quoteIcon from "../../../img/quote-ic.svg";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";

const QuoteSection = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "QuoteSection");

  return (
    <div className="QuoteSection">
      <Row>
        <Col className="icon-col">
          <Image className="quote-icon" src={quoteIcon} />
        </Col>
        <Col className="text-col">
          <Row>
            <Col className="quote-text-col">
              {t("insights:insights_quote_text")}
            </Col>
          </Row>
          <Row>
            <Col className="quote-source-col">
              {t("insights:insights_quote_source")}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default QuoteSection;
