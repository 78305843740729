import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useJobSortLabels, jobSortTypes } from "./JobSortType";
import { FormControl } from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import "./JobSort.scss";

export default function JobSort(props) {
  const { selectedValue, updateSelectedValue } = props;

  const { loading: jobSortLabelsLoading, getJobSortLabel } = useJobSortLabels();
  useShowLoader(jobSortLabelsLoading);

  const onChange = (event) => {
    event.preventDefault();
    updateSelectedValue(event.target.value);
  };

  const { t, loading } = useTranslationJobs();

  if (loading) return null;

  // RENDER
  return (
    <Row className="JobSort">
      <Col className="job-sort-label-col">{t("jobs:job_sort_label")}</Col>
      <Col className="form-control-col">
        <FormControl
          className="job-sort-form-control"
          as={"select"}
          custom
          name={"jobSort"}
          value={selectedValue}
          onChange={onChange}
        >
          {jobSortTypes.map((jobSortType) => (
            <option className={"option"} key={jobSortType} value={jobSortType}>
              {getJobSortLabel(jobSortType)}
            </option>
          ))}
        </FormControl>
      </Col>
    </Row>
  );
}
