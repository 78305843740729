import React from "react";
import { Col, Row } from "react-bootstrap";
import RouterUtils from "../../utils/RouterUtils";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import ActionButton from "../common/widgets/ActionButton";
import * as ROUTES from "../../constants/routes";
import Banner from "./Banner";
import "./ConnectionModeSignUp.scss";
import useReferrer from "../infra-no-ui/navigation/useReferrer";

export default function ConnectionModeSignUp() {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  const {redirectTo} = useReferrer();

  useShowLoader(tLoading, "ConnectionModeSignUp");
  if (tLoading) return null;

  function onClickEmployerSignIn(event) {
    event.preventDefault();
    RouterUtils.openExternalUrl(ROUTES.EMPLOYER_SIGN_UP);
  }

  const onClickSignIn = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.SIGN_UP, false);
  };
  return (
    <div className="ConnectionModeSignUp">
      <Row>
        <Col className="sign-in-action-col-container">
          <Row>
            <Col>
              <ActionButton className="sign-in-action" onClick={onClickSignIn}>
                {t("authentication-ui:sign_in_with_recruiter")}
              </ActionButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <ActionButton
                className="sign-in-action-employer"
                onClick={(event) => onClickEmployerSignIn(event)}
              >
                {t("authentication-ui:sign_in_with_employer")}
              </ActionButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Banner />
        </Col>
      </Row>
    </div>
  );
}
