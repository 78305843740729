import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Page from "../common/page/Page";
import SignUpForm from "./SignUpForm";
import "./SignUpPage.scss";

export default function SignUpPage() {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();

  useShowLoader(tLoading, "SignUpPage");

  return (
    <Page className="SignUpPage">
      <Page.Title
        text={t("authentication-ui:sign_up_title")}
        loading={tLoading}
      />
      <Page.Content>
        <div className={"SignUpPage-div"}>
          <Row>
            <Col className="sign-up-block-col">
              <SignUpBlock />
            </Col>
          </Row>
        </div>
      </Page.Content>
    </Page>
  );
}

function SignUpBlock() {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading);
  return (
    <div className="SignUpBlock">
      <Row>
        <Col>
          <h1>{t("authentication-ui:sign_up_title")}</h1>
        </Col>
      </Row>
      <Row>
        <Col className="singn-up-form-col">
          <div className="sign-up-form-div">
            <SignUpForm />
          </div>
        </Col>
      </Row>
    </div>
  );
}
