import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";
import React from "react";

/**
 * Dynamically load translation files pertaining to the employers module.
 * @return {*}
 */
export default function useTranslationEmployers() {

  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(
    () => ({
      en: import("./locales/en/employers.json"),
      fr: import("./locales/fr/employers.json"),
    }),
    []
  );

  return useModuleTranslation("employers", imports);
}
