import React from "react";
import useTranslationDashboard from "../useTranslationDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useMutationRecruiterAlertUpdate from "../mutate/useMutationRecruiterAlertUpdate";
import Alert from "@material-ui/lab/Alert";
import "./DashboardAlertsListItems.scss";
import useErrorModal from "../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import TypeUtils from "../../../utils/TypeUtils";

export default function DashboardAlertsListItems(props) {
  const { items: alerts } = props;

  const {
    mutate,
    loading,
    errors: errorsUpdateErrors,
  } = useMutationRecruiterAlertUpdate();

  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading || loading, "DashboardAlertsListItems");

  const errorMsg = useServerErrorFormatter(errorsUpdateErrors);

  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("dashboard:recruiters_dashboard_alerts_update_error")}</p>
      {errorMsg}
    </>
  );

  const emptyMsg = t("dashboard:recruiters_dashboard_empty_alerts_list_msg");

  const onClose = (id) => {
    const variables = {};
    variables["id"] = id;
    variables["status"] = "VIEWED";
    mutate({ variables }).catch((/*error*/) => {
      showErrorModal();
    });
  };

  return (
    <>
      {ErrorModal}
      <div className="DashboardAlertsListItems spaced-children">
        {TypeUtils.arrayIsEmpty(alerts) && (
          <span className={"empty-message"}>{emptyMsg}</span>
        )}
        {alerts.map((alert, i) => (
          <Alert
            key={i}
            className="alert-col"
            variant="filled"
            severity={alert.alertType.toLowerCase()}
            onClose={() => {
              onClose(alert._id);
            }}
          >
            {alert.message}
          </Alert>
        ))}
      </div>
    </>
  );
}
