import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "recruiterAlert";
const QUERY_NAME = "recruiterAlertUpdate";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $id: ID!,
    $status: RecruiterAlertStatusEnum
  ) {
    ${QUERY_SCOPE} {
    _id
      ${QUERY_NAME} (
        id: $id,
        status: $status
      ) {
        _id
        status
      }
    }
  }
`;

export default function useMutationRecruiterAlertUpdate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
