import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import "./ShareBar.scss";
import TypeUtils from "../../../utils/TypeUtils";

export default function ShareBar(props) {
  const { title, tags, url, linkedInTitle = undefined, linkedInSummary = undefined, facebookQuote = undefined,
    facebookHashtag = undefined, twitterTitle = undefined, twitterHashtags = undefined,
    emailSubject = undefined, emailBody = undefined } = props;

  const normalizeHashtag = (hashtag) =>
    hashtag.toLowerCase().replace(/ /g, "_");

  const hashtags = TypeUtils.ensureArray(tags).slice(0, 5)
    .map((item) => normalizeHashtag(item));
  const summary = title;
  const domainName = window.location.hostname;

  // RENDER
  return (
    <div className="ShareBar">
      <LinkedinShareButton
        title={linkedInTitle === undefined ? title : linkedInTitle}
        summary={linkedInSummary === undefined ? summary : linkedInSummary}
        url={url}
        source={domainName}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <FacebookShareButton
        url={url}
        quote={facebookQuote === undefined ? title : facebookQuote}
        hashtag={"#" + normalizeHashtag(facebookHashtag === undefined ? title : facebookHashtag)}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        title={twitterTitle === undefined ? title : twitterTitle}
        url={url}
        hashtags={twitterHashtags === undefined ? hashtags : twitterHashtags}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <EmailShareButton
        subject={emailSubject === undefined ? summary : emailSubject}
        body={emailBody === undefined ? title : emailBody}
        url={url}
        separator=": "
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );
}
