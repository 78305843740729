import React from "react";
import Page from "../../common/page/Page";
import LandingBlock from "./EmployerLandingBlock";
import useTranslationEmployerLanding from "../useTranslationEmployerLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";

export default function EmployerLandingPage(props) {

  const { t, loading: tLoading } = useTranslationEmployerLanding();
  useShowLoader(tLoading, "EmployerLandingBlock");

  return (
    <Page className="EmployerLandingPage">
      <Page.Title text={t("employer-landing:landing_title")} />
      <Page.Content className="spaced-children">
        <LandingBlock />
      </Page.Content>
    </Page>
  );
}
