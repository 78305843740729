import React, { useState } from "react";
import { contentfulClient } from "../../infra-no-ui/contentful/ContentfulClient";

export default function useContentful() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [errors, setErrors] = useState([]);

  const getContentEntries = React.useCallback((params) => {
    return contentfulClient
      .getEntries({
        ...params,
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        setErrors([error]);
        setLoading(false);
      });
  }, []);

  return {
    loading,
    data,
    errors,
    getContentEntries,
  };
}
