import React from "react";
import "./JobSearchResultList.scss";
import useQueryJobPublicSearch from "./useQueryJobPublicSearch";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WaitForData from "../../common/data-fetching/WaitForData";
import useTranslationJobs from "../useTranslationJobs";
import { SEARCH_PAGE_SIZE } from "../../../constants/ui";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ActionButton from "../../common/widgets/ActionButton";
import TypeUtils from "../../../utils/TypeUtils";
import useLanguages from "../../infra-no-ui/translation/useLanguages";

export default function JobSearchResultList(props) {
  const {
    searchFilters,
    sortValue,
    pageSizeValue,
    updatePageSizeValue,
    resultComponent,
  } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  const {getCurrentLanguageCode} = useLanguages();

  const {
    loading,
    loadingMore,
    errors,
    data: jobs,
    hasNextPage,
    loadMore,
  } = useQueryJobPublicSearch(pageSizeValue, searchFilters, sortValue, getCurrentLanguageCode());

  useShowLoader(tLoading, "JobSearchResultList");

  const onClickLoadMore = (event) => {
    event.preventDefault();
    loadMore();
    updatePageSizeValue(pageSizeValue + SEARCH_PAGE_SIZE);
  };

  // Enclose only the load more button inside WaitForData, to avoid the previous results from being hidden by the spinner
  return (
    <div className="JobSearchResultList">
      {TypeUtils.ensureArray(jobs).map((item, index) =>
        React.cloneElement(resultComponent, {
          key: index,
          item,
        })
      )}
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <>
            {TypeUtils.arrayIsEmpty(jobs) && <span>{t("jobs:job_no_result")}</span>}
            {hasNextPage && (
              <>
                <Row className="button-row">
                  <Col>
                    <ActionButton
                      loading={loadingMore}
                      onClick={onClickLoadMore}
                    >
                      {t("jobs:job_load_more")}
                    </ActionButton>
                  </Col>
                </Row>
                <Row className="expand-icon-row">
                  <Col>
                    <ExpandMoreIcon />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      />
    </div>
  );
}
