import React from "react";
import Page from "../../common/page/Page";
import useTranslationJobs from "../useTranslationJobs";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import JobListWithSearchPane from "../search/JobListWithSearchPane";
import StringUtils from "../../../utils/StringUtils";
import { SEARCH_PAGE_SIZE } from "../../../constants/ui";

export default function JobsPage(props) {
  const {companyNames: companyNamesProp} = props;
  const { t, loading: tLoading } = useTranslationJobs();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const {keyword: keywords, location: places, companyname: companyNamesSearch, sortby: sortBy, pagesize: pageSize, employmenttype: employmentType} = searchParams;

  const initialSortBy = StringUtils.nullToEmpty(sortBy) ? sortBy : "DATE_POSTED_DESC";
  const initialPageSize = StringUtils.nullToEmpty(pageSize) ? parseInt(pageSize) : SEARCH_PAGE_SIZE;
  const initialEmploymentType = StringUtils.nullToEmpty(employmentType) ? employmentType : "";

  // Company names passed as props have precedence over those passed as search string
  const companyNames = companyNamesProp ? companyNamesProp : companyNamesSearch;

  // Memoize search filters to avoid re-rendering of components that use them on every render of this component
  const initialSearchFilters = React.useMemo(() => {
    const initialSearchFilters = {}
    if (keywords && keywords.length > 0)
      initialSearchFilters["queryText"] = typeof keywords === 'string' ? [keywords] : keywords;
    if (places && places.length > 0)
      initialSearchFilters["location"] = typeof places === 'string' ? [places] : places;
    if (companyNames && companyNames.length > 0)
      initialSearchFilters["companyName"] = typeof companyNames === 'string' ? [companyNames] : companyNames;
    if (initialEmploymentType)
      initialSearchFilters["employmentType"] = initialEmploymentType
    return initialSearchFilters;
  }, [keywords, places, companyNames, initialEmploymentType]);

  return (
    <Page className="JobsPage">
      <Page.Title loading={tLoading}>
        {t("jobs:all_jobs_title")}
      </Page.Title>
      <Page.Content>
        <JobListWithSearchPane initialSearchFilters={initialSearchFilters} initialSortBy={initialSortBy} initialPageSize={initialPageSize} />
      </Page.Content>
    </Page>
  );
}
