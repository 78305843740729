import React from "react";
import Modal from "react-bootstrap/Modal";
import "./ConfirmationModal.scss";
import StatusIcon from "../../../img/warning.png";
import Image from "react-bootstrap/Image";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";
import ActionButton from "../widgets/ActionButton";

export default function ConfirmationModal(props) {
  const handleConfirm = (event) => {
    event.preventDefault();
    onConfirm();
  };

  const { show, onDismiss, onConfirm, children, loading } = props;

  const { t, loading: tLoading } = useTranslationCommon();
  useShowLoader(tLoading, "ConfirmationModal");

  // RENDER

  return (
    <Modal show={show} onHide={onDismiss} className="ConfirmationModal">
      <Modal.Body>
        <div>
          <Image src={StatusIcon} className={"status-icon"} />
        </div>
        <div>{children ? children : null}</div>
        <div className={"button-row"}>
          <ActionButton onClick={onDismiss}>
            {t("common:confirmation_cancel_button")}
          </ActionButton>
          <ActionButton onClick={handleConfirm} loading={loading}>
            {t("common:confirmation_confirm_button")}
          </ActionButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}
