import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "recruiter";
const QUERY_NAME = "recruiterById";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        _id
        firstName
        lastName
        phone
        email
        jobTitle
        city
        country
        professional
        aboutMe
      }
    }
  }
`;

export default function useQueryRecruiterById(id) {

  const variables = {id};

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME,{variables});
}
