import React from "react";
import AppRouter from "./AppRouter";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary";
import UntranslatedContentError from "../common/error-boundaries/UntranslatedContentError";

// Main App
export default function App() {

    return (
      <ErrorBoundary component={<UntranslatedContentError/>}>
        <AppRouter/>
      </ErrorBoundary>
    );
}
