import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "insight";
const QUERY_NAME = "insightCount";

export const QUERY = gql`
  query ${QUERY_NAME}_${QUERY_SCOPE}(
    $queryText: String
    $language: String
  ) {
    ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
        queryText: $queryText
        language:$language

      ) 
    }
  }
`;

export default function useQueryInsightCount(queryText, language) {
  const variables = { queryText, language };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
  });
}
