import { gql } from "@apollo/client";
import useWrappedMutation from "../common/data-fetching/useWrappedMutation";


const QUERY_SCOPE = "contactUsEmailPublic";
const QUERY_NAME = "contactUsEmailCreate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $senderName: String!
      $senderEmail: String!
      $senderPhone: String
      $senderType: ContactUSEmailSenderTypeEnum!
      $subject: String!
      $text: String!
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          senderName: $senderName,
          senderEmail: $senderEmail,
          senderPhone: $senderPhone,
          senderType: $senderType,
          subject: $subject,
          text: $text,
        ) 
        
      }
    }
  `;

export default function useMutationContactUsEmailCreate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
