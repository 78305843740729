import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Faq from "react-faq-component";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationLanding from "../useTranslationLanding";
import FaqSeo from "../../common/seo/FaqSeo"
import CallToActionSection from "./CallToActionSection";
import "./FaqSection.scss";
import WaitForData from "../../common/data-fetching/WaitForData";

export default function FaqSection(props) {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "JobSearchItem");

  const data = {
    rows: [
      {
        title: t("landing:landing_faq_question_1_text"),
        content: t("landing:landing_faq_response_1_text"),
      },
      {
        title: t("landing:landing_faq_question_2_text"),
        content: t("landing:landing_faq_response_2_text"),
      },
      {
        title: t("landing:landing_faq_question_3_text"),
        content: t("landing:landing_faq_response_3_text"),
      },
      {
        title: t("landing:landing_faq_question_4_text"),
        content: t("landing:landing_faq_response_4_text"),
      },
      {
        title: t("landing:landing_faq_question_5_text"),
        content: t("landing:landing_faq_response_5_text"),
      },
      {
        title: t("landing:landing_faq_question_6_text"),
        content: t("landing:landing_faq_response_6_text"),
      },
      {
        title: t("landing:landing_faq_question_7_text"),
        content: t("landing:landing_faq_response_7_text"),
      },
      {
        title: t("landing:landing_faq_question_8_text"),
        content: t("landing:landing_faq_response_8_text"),
      },
    ]
  };

  const url = window.location.hostname;
  const description = t("landing:landing_description_text");
  const title = t("landing:landing_title_text");

  // RENDER
  return (
    <>
      <FaqSeo
        title={title}
        description={description}
        questions={data.rows.map((q) => q.title)}
        answers={data.rows.map((q) => q.content)}
        url={url}
      />
      <div className="FaqSection">
        <Row className="faq-row-wrapper">
          <Col>
            {/* Do not mount Faq until translation strings are ready because Faq computes vertical space at mount time */}
            <WaitForData loading={tLoading} onLoaded={() => <Faq data={data}/>}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <CallToActionSection />
          </Col>
        </Row>
      </div>
    </>
  );
}
