import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryJobById from "./useQueryJobById";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import EmployerItem from "./EmployerItem";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DisplayDate from "../../common/widgets/DisplayDate";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BusinessIcon from "@material-ui/icons/Business";
import DisplayApplicationStatus from "./DisplayApplicationStatus";
import "./ApplicationListItem.scss";
import DisplayApplicationStatusEffectiveDate from "./DisplayApplicationStatusEffectiveDate";
import ApplicationStatusEnum from "../ApplicationStatusEnum.js";

export default function ApplicationListItem(props) {
  const { application } = props;

  const { data: job, loading, errors } = useQueryJobById(application.jobId);
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationListItem");

  return (
    <div className="ApplicationListItem">
      <HorizontalLoader>
        <WaitForData
          loading={loading}
          errors={errors}
          onError={() => <ReportProblemOutlined className="LoadingError" />}
          onLoaded={() => (
            <Row className="ApplicationListItem">
              <Col className="job-title-col">{job.title}</Col>
              <Col className="employer-item-col">
                <Row>
                  <Col className="icon-col">
                    <BusinessIcon />
                  </Col>
                  <Col className="icon-title">
                    <EmployerItem organizationId={job.organizationId} />
                  </Col>
                </Row>
              </Col>
              <Col className="commission-col">
                <Row>
                  <Col className="icon-col">
                    <MonetizationOnIcon />
                  </Col>
                  <Col className="icon-title">
                    {t("candidates:candidates_job_details_commission_amounts", {
                      amount: job.commission,
                    })}
                  </Col>
                </Row>
              </Col>
              <Col className="status-col">
                <Row>
                  <Col>
                    <DisplayApplicationStatus application={application} job={job} />
                  </Col>
                </Row>
                {(application.status === ApplicationStatusEnum.HIRED || application.status === ApplicationStatusEnum.HIRING_CANCELLED) &&
                  <Row>
                    <Col>
                      <HorizontalLoader>
                        <DisplayApplicationStatusEffectiveDate applicationId={application._id}/>
                      </HorizontalLoader>
                    </Col>
                  </Row>
                }
              </Col>

              <Col className="date-col">
                <Row>
                  <Col className="icon-col">
                    <DateRangeIcon />
                  </Col>
                  <Col className="icon-title">
                    <DisplayDate date={application.createdAt} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        />
      </HorizontalLoader>
    </div>
  );
}
