import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CandidateSaveApplicationForm.scss";
import ValidatedInputWysiwyg from "../../common/widgets/ValidatedInputWysiwyg";
import {
  formatWysiwygInput,
} from "../../common/widgets/WysiwygEditor";
import { MAX_LENGTH_APPLICATION_NOTE } from "../../../constants/ui";
import WysiwygCounterDisplay from "../../common/widgets/WysiwygCounterDisplay";
import ValidatedInputCheckbox
  from "../../common/widgets/ValidatedInputCheckbox";

export default function CandidateSaveApplicationForm({t, control, textLength, job}) {

  const workPermitChoices = React.useMemo(() => {
    if (job)
      return [{
        value: true,
        label: t("candidates:candidates_save_application_work_permit_required_label", {country: job.country})
      }]
    else
      return [];
  }, [t, job]);

  return (
    <div className="CandidateSaveApplicationForm widget-block">
      {job.isWorkPermitRequired &&
        <Row>
          <Col className={"field-container"}>
            <Row>
              <Col className={"invite"}>{t("candidates:candidates_save_application_qualifications_msg")}</Col>
            </Row>
            <Row>
              <Col>
                <ValidatedInputCheckbox
                  name={"hasWorkPermit"}
                  control={control}
                  choices={workPermitChoices}
                  defaultValue={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }
      <Row>
        <Col className={"field-container"}>
          <Row>
            <Col className={"invite"}>{t("candidates:candidates_save_application_invite_msg")}</Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputWysiwyg
                name={"note"}
                control={control}
                defaultValue={formatWysiwygInput("")}
                placeholder={t(
                  "candidates:candidates_save_candidate_note_step_placeholder"
                )}
                required
                maxLength={MAX_LENGTH_APPLICATION_NOTE}
              />
              <WysiwygCounterDisplay
                maxLength={MAX_LENGTH_APPLICATION_NOTE}
                length={textLength}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
