import React from "react";
import HtmlUtils from "../../../utils/HtmlUtils";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./RewardPaymentTerms.scss";

export default function RewardPaymentTerms({organization}) {

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "RewardPaymentTerms");

  return (
    <div className={"RewardPaymentTerms"}>
      <p>{t("jobs:reward_payment_terms_msg_1")}</p>
      {HtmlUtils.htmlToComponents(organization.paymentTerms)}
      <p className={"small-note"}>{HtmlUtils.htmlToComponents(t("jobs:reward_payment_terms_msg_2", {company: `<span class="company-name">${organization.company}</span>`}))}</p>
      <p className={"small-note"}>{HtmlUtils.htmlToComponents(t("jobs:reward_payment_terms_msg_3", {company: `<span class="company-name">${organization.company}</span>`}))}</p>
      <p className={"small-note"}>{HtmlUtils.htmlToComponents(t("jobs:reward_payment_terms_msg_4", {company: `<span class="company-name">${organization.company}</span>`}))}</p>
    </div>
  );
};
