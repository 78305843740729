import React from "react";
import useTranslationProfile from "../useTranslationProfile";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useMutationProfileDelete from "./useMutationProfileDelete";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import ActionLink from "../../common/widgets/ActionLink";
import useFeedbackModal from "../../common/modals/useFeedbackModal";
import useDeleteUser from "../../authentication-no-ui/useDeleteUser";
import "./ProfileDeleteButton.scss";
export default function ProfileDeleteButton(props) {
  const { recruiterId } = props;

  const { deleteUser } = useDeleteUser();

  const { t, loading: tLoading } = useTranslationProfile();
  useShowLoader(tLoading, "ProfileDeleteButton");

  const deleteProfile = () => {
    return mutate({
      variables: {
        id: recruiterId,
      },
    });
  };

  const onCompleted = () => {
    deleteUser();
  };

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(deleteProfile, onCompleted);
  };

  const {
    mutate,
    loading: deleteLoading,
    errors: deleteErrors,
  } = useMutationProfileDelete();
  const successMsg = (
    <p>{t("profile:recruiters_profile_delete_success_msg")}</p>
  );
  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmMsg = (
    <p>{t("profile:recruiters_profile_delete_confirm_msg")}</p>
  );
  const { launch, FeedbackModal } = useFeedbackModal({
    successMsg,
    errorMsg,
    confirmMsg
  });

  return (
    <>
      {FeedbackModal}
      <ActionLink onClick={onClickDelete} loading={deleteLoading}>
        <span className="deleteButton">
          {t("profile:recruiters_profile_delete_action")}
        </span>
      </ActionLink>
    </>
  );
}
