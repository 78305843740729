import React from "react";
import useContentModal from "../../common/modals/useContentModal";
import ApplicationForm from "../mutate/ApplicationForm";
import "./ApplicationByCandidateSearchResult.scss";
import ActionButton from "../../common/widgets/ActionButton";
import ApplicationListItem from "../view/ApplicationListItem";

export default function ApplicationByCandidateSearchResult(props) {
  const { item: application } = props;
  // Modal to search application form
  const {
    ContentModal: applicationModal,
    show: showApplicationModal,
    hide: hideApplicationModal,
  } = useContentModal("ApplicationModal");

  const onClickApplication = () => {
    showApplicationModal(getApplication());
  };

  const getApplication = () => {
    return (
      <ApplicationForm
        onClose={hideApplicationModal}
        applicationId={application._id}
        jobId={application.jobId}
      />
    );
  };

  return (
    <>
      {applicationModal}
      <ActionButton
        as={"div"}
        variant="outline-primary"
        onClick={(event) => onClickApplication(event)}
        className={"ApplicationByCandidateSearchResult"}
      >
        <ApplicationListItem application={application} />
      </ActionButton>
    </>
  );
}
