import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import CandidateProfileInlineEditInputText from "../mutate/CandidateProfileInlineEditInputText";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ApplicationsByCandidateList from "./ApplicationsByCandidateList";
import ResumeDownloadLink from "./ResumeDownloadLink";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import useQueryApplicationByCandidateRemainingCount from "./useQueryApplicationByCandidateRemainingCount";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import RefreshButton from "../../common/widgets/RefreshButton";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import InformationDisplay from "../../common/widgets/InformationDisplay";
import "./CandidateProfileBlock.scss";

export default function CandidateProfileBlock(props) {
  const { candidate } = props;
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationsList");

  const hasResume = candidate.resumeId ? true : false;

  const {
    data: remainingApplicationsCount,
    loading,
    errors,
  } = useQueryApplicationByCandidateRemainingCount(candidate._id);

  const listTitle = t("candidates:candidates_application_list_title");
  const applicationInfo = t("candidates:candidates_application_list_label", {
    count: remainingApplicationsCount,
  });

  return (
    <div className="CandidateProfileBlock ">
      <Col className="candidate-profile-block ">
        <Row>
          <Col className="name-col">
            <CandidateProfileInlineEditInputText
              candidate={candidate}
              property={"name"}
              placeholder={t(
                "candidates:candidates_update_candidate_name_placeholder"
              )}
            />
          </Col>
          <Col className="refresh-col">
            <RefreshButton />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col className="candidate-contact-col">
                <Row>
                  <Col className="icon-col">
                    <PhoneIcon />
                  </Col>
                  <Col
                    className={candidate.phone ? "phone-col" : "icon-data-col"}
                  >
                    <CandidateProfileInlineEditInputText
                      candidate={candidate}
                      viewAs="span"
                      property={"phone"}
                      placeholder={t(
                        "candidates:candidates_update_candidate_phone_placeholder"
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="icon-col">
                    <EmailIcon />
                  </Col>
                  <Col className="email-col">
                    <CandidateProfileInlineEditInputText
                      candidate={candidate}
                      viewAs="span"
                      property={"email"}
                      placeholder={t(
                        "candidates:candidates_update_candidate_email_placeholder"
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="icon-col">
                    <LinkedInIcon />
                  </Col>
                  <Col
                    className={
                      candidate.linkedin ? "linkedin-col" : "icon-data-col"
                    }
                  >
                    <CandidateProfileInlineEditInputText
                      candidate={candidate}
                      viewAs="span"
                      property={"linkedin"}
                      placeholder={t(
                        "candidates:candidates_update_candidate_linkedin_placeholder"
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="icon-col">
                    <AccountBoxIcon />
                  </Col>
                  <Col
                    className={
                      candidate.indeed ? "indeed-col" : "icon-data-col"
                    }
                  >
                    <CandidateProfileInlineEditInputText
                      candidate={candidate}
                      viewAs="span"
                      property={"indeed"}
                      placeholder={t(
                        "candidates:candidates_update_candidate_indeed_placeholder"
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="icon-col">
                    <CloudDownloadIcon
                      alt={t(
                        "candidates:candidates_details_download_resume_action"
                      )}
                    />
                  </Col>
                  <Col className="download-link-col">
                    <ResumeDownloadLink
                      candidate={candidate}
                      disabled={!hasResume}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="information-display-col">
            <InformationDisplay
              hoverInformation={t("candidates:candidates_hover_information")}
              information={applicationInfo}
            />
          </Col>
        </Row>
        <Row>
          <Col className="application-list-col">
            <Row>
              <Col>
                <PageSubtitle colTitle={listTitle} />
              </Col>
            </Row>
            <Row className="applications-row">
              <Col>
                <WaitForData
                  loading={loading}
                  errors={errors}
                  onError={() => (
                    <ReportProblemOutlined className="LoadingError" />
                  )}
                  onLoaded={() => (
                    <ApplicationsByCandidateList
                      candidateId={candidate._id}
                      remainingApplicationsCount={remainingApplicationsCount}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="applications-row">
              <Col className="indication-col">
                {t(
                  "candidates:candidates_details_application_approval_message"
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
