import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "insight";
const QUERY_NAME = "insightTopCities";

export const QUERY = gql`
  query ${QUERY_NAME}_${QUERY_SCOPE}(
    $queryText: String
    $limit: Int
    $language: String
  ) {
    ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
        queryText: $queryText
        limit: $limit
        language:$language
      ) {
        _id
        totalCount
      }
    }
  }
`;

export default function useQueryInsightTopCities(queryText, limit, language) {
  const variables = { queryText, limit, language };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
  });
}
