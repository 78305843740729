import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "recruiter";
const QUERY_NAME = "recruiterByEmail";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($email: String!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(email: $email) {
        _id
        firstName
        lastName
        phone
        email
        jobTitle
        aboutMe
        sponsorCode
        defaultLanguage
      }
    }
  }
`;

export default function useQueryRecruiterByEmail(email) {

  const variables = {
    email: email
  };

  // If email is null, skip query and return a null profile
  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME,{variables, skip: !email});
}
