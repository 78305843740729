import React from "react";
import Helmet from "react-helmet";
import CommonSeo from "./CommonSeo";

export default function FaqSeo(props) {
  const {
    title,
    description,
    questions,
    answers,
    url
  } = props;

  const faq = questions.map((item, i) => `{
      "@type": "Question",
      "name": "${item}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${answers[i]}"
      }
    }`)
  const structuredDataFaq = `{
      "@context" : "https://schema.org/",
      "@type" : "FAQPage",
      "mainEntity": [${faq}]
    }`;

  return (
    <>
      <CommonSeo url={url} description={description} title={title} />
      <Helmet>
        {/* Structured data */}
        <script type="application/ld+json">
          {structuredDataFaq}
        </script>
      </Helmet>
    </>
  );
};
