import React from "react";
import Page from "../../common/page/Page";
import SponsorshipBlock from "./SponsorshipBlock";
import useTranslationSponsorship from "../useTranslationSponsorship";
import useShowLoader from "../../common/loading-widgets/useShowLoader";

export default function SponsorshipPage() {

  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading, "SponsorshipPage");

  return (
    <Page className="SponsorshipPage">
      <Page.Content className="spaced-children" title={t("sponsorship:sponsorship_landing_title")}>
        <SponsorshipBlock />
      </Page.Content>
    </Page>
  );
}
