import {gql} from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "recruiter";
const QUERY_NAME = "recruiterDelete";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} ($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} (id: $id) {
        _id
      }
    }
  }
`;

export default function useMutationProfileDelete() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
