import React from "react";
import "./JobSearchItemLanding.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StringUtils from "../../../utils/StringUtils";
import PlaceIcon from "@material-ui/icons/Place";
import BusinessIcon from "@material-ui/icons/Business";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import ActionLink from "../../common/widgets/ActionLink";
import useTranslationLanding from "../useTranslationLanding";
import TypeUtils from "../../../utils/TypeUtils";
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

export default function JobSearchItemLanding(props) {
  const {redirectTo} = useReferrer();

  const onClickViewAll = () => {
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_JOB, {
        jobId: job._id,
      })
    );
  };

  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "JobSearchItemLanding");

  const {getCurrentLanguageCode} = useLanguages();

  const { item: job } = props;

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state, job.country])
    .join(", ");

  // RENDER

  return (
    <Card className="JobSearchItemLanding" id={job._id}>
      <Card.Body className="job-search-card">
        <Row className="job-search-row-container">
          <Col className="jobInformation">
            <Row>
              <Col>
                <ActionLink onClick={onClickViewAll} className="job-link">
                  {job.title}
                </ActionLink>
              </Col>
            </Row>
            <Row className="logo-row">
              <Col className="icon-col">
                <BusinessIcon />
              </Col>
              <Col>{job.organization && job.organization.company}</Col>
              {!StringUtils.isNullOrEmpty(addressLine) && (
                <Row>
                  <Col className="icon-col">
                    <PlaceIcon />
                  </Col>
                  <Col>{addressLine}</Col>
                </Row>
              )}
            </Row>
          </Col>
          <Col className="last-col">
            <Row>
              <Col className="commission-title-col">
                {t("landing:landing_job_commission_title")}
              </Col>
            </Row>
            <Row>
              <Col className="commission-col">
                {StringUtils.formatMoney(job.commission, getCurrentLanguageCode())}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
