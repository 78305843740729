import "./InsightsResultBlock.scss";
import { Row, Col } from "react-bootstrap";
import CompareWithPane from "./CompareWithPane";
import TechnicalSkillsCard from "./TechnicalSkillsCard";
import SoftSkillsCard from "./SoftSkillsCard";
import ResponsibilitiesCard from "./ResponsibilitiesCard";
import SimilarJobsResultList from "../../jobs/search/SimilarJobsResultList";
import CompaniesCard from "./CompaniesCard";
import CitiesCard from "./CitiesCard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import cssVariables from "./Charts.module.scss";
import * as ROUTES from "../../../constants/routes";
import ActionLink from "../../common/widgets/ActionLink";
import Loader from "../../common/loading-widgets/Loader";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

const CHART_COLOR = cssVariables.firstChartColor;

const InsightsResultBlock = (props) => {
  const { keyword, insightCount, language } = props;

  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "InsightsResultBlock");

  const {redirectTo} = useReferrer();

  const onClickChange = () => {
    redirectTo({
      pathname: ROUTES.RECRUITER_MARKET_INSIGHTS_TOOL,
    });
  };

  const responsibilitiesCardTitle = t(
    "insights:insights_result_page_responsibilities_card_title",
    { keyword }
  );
  const companiesCardTitle = t(
    "insights:insights_result_page_companies_card_title",
    { keyword }
  );
  const citiesCardTitle = t("insights:insights_result_page_cities_card_title", {
    keyword,
  });

  return (
    <div className="InsightsResultBlock">
      <Row>
        <Col className="simple-spaced-rows">
          <Row>
            <Col>
              <div>
                <span className="title-span">
                  {t("insights:insights_result_page_title", {
                    keyword,
                  })}
                </span>
                <span className="change-link-sapn">
                  <ActionLink onClick={onClickChange}>
                    {t("insights:insights_result_page_change_link_label")}
                  </ActionLink>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="comparaison-col">
              <Row>
                <Col>
                  {t("insights:insights_result_page_compare_with_label", {
                    keyword,
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CompareWithPane keyword={keyword} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="card-col">
          <Row>
            <Col>
              <h2>{t("insights:insights_technical_skills_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <TechnicalSkillsCard
                keyword={keyword}
                language={language}
                chartColor={CHART_COLOR}
                displayLegend={false}
                insightCount={insightCount}
              />
            </Col>
          </Row>
        </Col>
        <Col className="card-col">
          <Row>
            <Col>
              <h2>{t("insights:insights_soft_skills_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <SoftSkillsCard
                keyword={keyword}
                language={language}
                chartColor={CHART_COLOR}
                displayLegend={false}
                insightCount={insightCount}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <h2>{t("insights:insights_responsibilities_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <ResponsibilitiesCard
                keyword={keyword}
                language={language}
                title={responsibilitiesCardTitle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="card-col">
          <Row>
            <Col>
              <h2>{t("insights:insights_companies_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <CompaniesCard
                keyword={keyword}
                language={language}
                title={companiesCardTitle}
              />
            </Col>
          </Row>
        </Col>
        <Col className="card-col">
          <Row>
            <Col>
              <h2>{t("insights:insights_cities_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <CitiesCard
                keyword={keyword}
                language={language}
                title={citiesCardTitle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Loader>
            <SimilarJobsResultList query={keyword} currentJobId={null} />
          </Loader>
        </Col>
      </Row>
    </div>
  );
};

export default InsightsResultBlock;
