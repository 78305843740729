import React from "react";
import Button from "react-bootstrap/Button";
import "./PillList.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";

export default class PillList extends React.Component {

  /**
   * Trim an array of strings,
   * filter out empty strings, capitalize first letter and remove duplicates
   * (using a Set converted back to an array). Items displayed in PillList are processed with this function
   * beforehand to, at least, remove duplicates (this would cause a duplicate React key error).
   * @param items
   * @param capitalize Capitalize first letter if true
   * @returns {string[]}
   */
  static formatStrings = (items, capitalize = true) => {

    if (!Array.isArray(items))
      return [];

    return [
      ...new Set(
        items
          .map(item => item.trim())
          .filter(item => item.length > 0)
          .map(item => {
            if (capitalize) return item.charAt(0).toUpperCase() + item.slice(1);
            else return item;
          })
      )
    ];
  };


  render() {
    const altTestIsDefined = this.props.altTest && typeof(this.props.altTest === "function");
    const altTest = altTestIsDefined ? this.props.altTest : () => false;

    return (
      <Row className="PillList">
        {this.props.items.map(item =>
          <Col xs={this.props.wrap ? "12" : "auto"} key={item} className="tag-col">
          <Pill item={item} variant={altTest(item) ? this.props.altVariant : this.props.variant} onClickItem={this.props.onClickItem}/>
        </Col>
        )}
      </Row>
    );
  }
}

class Pill extends React.Component {

  render() {
    const onClickIsDefined = this.props.onClickItem && typeof(this.props.onClickItem === "function");

    // Click event is defined, we want buttons (change color and pointer when hovering)
    if (onClickIsDefined)
      return (
        <Button
          variant={this.props.variant}
          className="Pill"
          onClick={event => this.props.onClickItem(event, this.props.item)}
        >
          {this.props.item}
        </Button>
      );

    // Click event is not defined, we want unclickable badges (they have a similar look to buttons)
    return (
      <Badge
        variant={this.props.variant}
        className="Pill"
      >
        {this.props.item}
      </Badge>
    );
  }
}
