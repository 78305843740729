import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";
import React from "react";

/**
 * Dynamically load translation files pertaining to the dashboard module.
 * @return {*}
 */
export default function useTranslationLanding() {

  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(() => ({
    'en': import('./locales/en/landing.json'),
    'fr': import('./locales/fr/landing.json')
  }), []);

  return useModuleTranslation('landing', imports);
}
