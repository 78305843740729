import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationByCandidateRemainingCount";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($candidateId: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(candidateId: $candidateId)
    }
  }
`;

export default function useQueryApplicationByCandidateRemainingCount(
  candidateId
) {
  const variables = { candidateId };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
