import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useTranslationDashboard from "../useTranslationDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import "./SponsorshipItem.scss";
import useQuerySponsoredRecruiterCount from "./useQuerySponsoredRecruiterCount";
import ActionLink from "../../common/widgets/ActionLink";
import StringUtils from "../../../utils/StringUtils";
import * as ROUTES from "../../../constants/routes";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

export default function SponsorshipItem(props) {
  const { recruiterId } = props;

  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "SponsorshipItem");

  const {
    data: recruiter,
    loading,
    errors,
  } = useQuerySponsoredRecruiterCount(recruiterId);

  const {redirectTo} = useReferrer();

  const goToProgramDetails = () => {
    redirectTo(ROUTES.SPONSORSHIP);
  }

  const goToSponsoredList = () => {
    redirectTo(ROUTES.SPONSORED);
  }

  const sponsoredCountMessage =
    <>
      <span>{t("dashboard:recruiters_dashboard_sponsorship_sponsoring_label")}</span>
      <ActionLink onClick={goToSponsoredList} className={"spotter-count"}>{t("dashboard:recruiters_dashboard_sponsorship_count_label", {count: StringUtils.nullToZero(recruiter && recruiter.sponsoredRecruiterCount)})}</ActionLink>
    </>;

  const programDetailsMessage = <ActionLink onClick={goToProgramDetails}>{t("dashboard:recruiters_dashboard_sponsorship_details_label")}</ActionLink>;
  const startSponsoringMessage =
    <>
      <ActionLink onClick={goToProgramDetails}>{t("dashboard:recruiters_dashboard_sponsorship_start_label")}</ActionLink><br/>
      <span>{t("dashboard:recruiters_dashboard_sponsorship_revenue_label")}</span>
    </>;

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="SponsorshipItem">
            <Row>
              <Col className="hired-candidates">
                <PageSubtitle colTitle={t("dashboard:recruiters_dashboard_sponsorship_title")} />
              </Col>
            </Row>
            <Row>
              <Col className="sponsor-stats">
                {sponsoredCountMessage}
              </Col>
            </Row>
            <Row className="program-details">
              <Col>
                {recruiter.sponsoredRecruiterCount ? programDetailsMessage : startSponsoringMessage}
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
