  import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryStatusByRecruiterIdGroupAndCount from "./useQueryStatusByRecruiterIdGroupAndCount";
import useTranslationDashboard from "../useTranslationDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import DashboardApplicationByStatusCount from "./DashboardApplicationByStatusCount";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import "./CandidatesByStatusCountItem.scss";
  import ApplicationStatusEnum from "../../candidates/ApplicationStatusEnum";

export default function CandidatesByStatusCountItem(props) {
  const { recruiterId } = props;

  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "DashboardBlock");

  const {
    data: counts,
    loading,
    errors,
  } = useQueryStatusByRecruiterIdGroupAndCount(recruiterId);

  const getCountForStatus = (status) => {
    const statusItem = counts.find((item) => item.status === status);
    return statusItem ? statusItem["count"] : 0;
  };

  const statusStackLabel = t(
    "dashboard:recruiters_dashboard_applications_list_stack_label"
  );
  const statusShortlistLabel = t(
    "dashboard:recruiters_dashboard_applications_list_shortlist_label"
  );
  const statusColTitle = t(
    "dashboard:recruiters_dashboard_applications_hired_title"
  );

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="CandidatesByStatusCountItem">
            <Row>
              <Col className="hired-candidates">
                <PageSubtitle colTitle={statusColTitle} />
              </Col>
            </Row>
            <Row>
              <Col className="statusCount">
                <span className="countSpan">
                  {t(
                    "dashboard:recruiters_dashboard_applications_hired_label",
                    {
                      count: getCountForStatus(ApplicationStatusEnum.HIRED),
                    }
                  )}
                </span>
              </Col>
            </Row>
            <Row className="statusInformationgroup">
              <Col>
                <Row>
                  <Col>
                    <DashboardApplicationByStatusCount
                      label={statusStackLabel}
                      count={getCountForStatus(ApplicationStatusEnum.STACK)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DashboardApplicationByStatusCount
                      label={statusShortlistLabel}
                      count={getCountForStatus(ApplicationStatusEnum.SHORTLIST)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
