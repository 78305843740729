import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EmployerHeaderBlock from "./EmployerHeaderBlock";
import JobListWithSearchPane from "../search/JobListWithSearchPane";
import queryString from "query-string";
import StringUtils from "../../../utils/StringUtils";
import { useLocation } from "react-router-dom";
import { SEARCH_PAGE_SIZE } from "../../../constants/ui";
import useTranslationEmployers from "../useTranslationEmployers";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import CommonSeo from "../../common/seo/CommonSeo";

export default function EmployerBlock(props) {
  const { organization, isInternal } = props;

  const { t, loading: tLoading } = useTranslationEmployers();
  useShowLoader(tLoading, "EmployerBlock");

  // Add search parameters to the URL
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const {
    keyword: keywords,
    sortby: sortBy,
    pagesize: pageSize,
    employmenttype: employmentType,
  } = searchParams;

  const initialSortBy = StringUtils.nullToEmpty(sortBy)
    ? sortBy
    : "DATE_POSTED_DESC";

  const initialPageSize = StringUtils.nullToEmpty(pageSize)
    ? parseInt(pageSize)
    : SEARCH_PAGE_SIZE;

  const initialEmploymentType = StringUtils.nullToEmpty(employmentType)
    ? employmentType
    : "";

  // Memoize search filters to avoid re-rendering of components that use them on every render of this component
  const initialSearchFilters = React.useMemo(() => {
    const initialSearchFilters = {};
    initialSearchFilters["companyName"] = [organization.company];
    initialSearchFilters["location"] = [];

    if (keywords && keywords.length > 0)
      initialSearchFilters["queryText"] =
        typeof keywords === "string" ? [keywords] : keywords;

    if (initialEmploymentType)
      initialSearchFilters["employmentType"] = initialEmploymentType;
    return initialSearchFilters;
  }, [keywords, organization.company, initialEmploymentType]);

  return (
    <div className="EmployerBlock">
      {!isInternal &&
      <CommonSeo
        description={t("employers:employer_company_page_meta_description", {
          company: organization.company,
        })}
        title={t("employers:employer_company_page_title", {
          company: organization.company,
        })}
      />
      }
      <Row className="rowContainer">
        <Col className="job-similar-col">
          <EmployerHeaderBlock organization={organization} isInternal />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <JobListWithSearchPane
            companyName={organization.company}
            initialSearchFilters={initialSearchFilters}
            initialSortBy={initialSortBy}
            initialPageSize={initialPageSize}
            isInternal={isInternal}
          />
        </Col>
      </Row>
    </div>
  );
}
