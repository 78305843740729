import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationCandidates from "../useTranslationCandidates";
import "./CandidateUpdateResume.scss";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ActionButton from "../../common/widgets/ActionButton";
import useErrorModal from "../../common/modals/useErrorModal";
import useResumeDropbox from "./useResumeDropbox";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";

export default function CandidateUpdateResume(props) {
  const { candidate, onClose } = props;

  const { t, loading: tLoading } = useTranslationCandidates();

  const {
    ResumeDropbox,
    ready: dropboxReady,
    errors: dropboxErrors,
  } = useResumeDropbox(candidate, "dropbox");
  useShowLoader(tLoading && !dropboxReady, "CandidateUpdateResume");

  const dropboxErrorMsg = useServerErrorFormatter(dropboxErrors, t("candidates:candidates_details_update_error"));
  const { ErrorModal, show: showErrorModal } = useErrorModal(
      <>
        {dropboxErrorMsg}
      </>
  );

  // Show upload error when an error occurs after upload
  React.useEffect(() => {
    if (dropboxErrors) {
      showErrorModal();
    }
  }, [dropboxErrors, showErrorModal]);

  return (
    <>
      {ErrorModal}
      <Row className="CandidateUpdateResume">
        <Col>{ResumeDropbox}</Col>
      </Row>
      <Row className="CandidateUpdateResume">
        <Col className="form-actions">
          <ActionButton onClick={onClose}>
            {t("candidates:candidates_close_action")}
          </ActionButton>
        </Col>
      </Row>
    </>
  );
}
