import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Faq from "react-faq-component";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationEmployerLanding from "../useTranslationEmployerLanding";
import FaqSeo from "../../common/seo/FaqSeo";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import "./FaqSectionEmployers.scss";

export default function FaqSectionEmployers(props) {
  const { t, loading: tLoading } = useTranslationEmployerLanding();
  useShowLoader(tLoading, "JobSearchItem");

  const data = {
    rows: [
      {
        title: t("employer-landing:landing_faq_question_1_text"),
        content: t("employer-landing:landing_faq_response_1_text"),
      },
      {
        title: t("employer-landing:landing_faq_question_2_text"),
        content: t("employer-landing:landing_faq_response_2_text"),
      },
      {
        title: t("employer-landing:landing_faq_question_3_text"),
        content: t("employer-landing:landing_faq_response_3_text"),
      },
      {
        title: t("employer-landing:landing_faq_question_4_text"),
        content: t("employer-landing:landing_faq_response_4_text"),
      },
    ],
  };

  const domainName = window.location.hostname;
  const url =
    domainName +
    RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_EMPLOYER_LANDING);
  const description = t("employer-landing:landing_description_text");
  const title = t("employer-landing:landing_title_text");

  // RENDER
  return (
    <>
      <FaqSeo
        title={title}
        description={description}
        questions={data.rows.map((q) => q.title)}
        answers={data.rows.map((q) => q.content)}
        url={url}
      />
      <div className="FaqSection">
        <Row>
          <Col className="title-col">
            {t("employer-landing:landing_faq_title")}
          </Col>
        </Row>
        <Row className="faq-row-wrapper">
          <Col>
            <Faq data={data} />
          </Col>
        </Row>
      </div>
    </>
  );
}
