import BarChart from "./BarChart";
import MathUtils from "../../../utils/MathUtils";

const SoftSkillsChart = (props) => {
  const { softSkillsData, keyword, chartColor, displayLegend, insightCount } =
    props;

  const softSkills = softSkillsData.map((softSkill) => softSkill._id);

  const softSkillsTotalCount = softSkillsData.map((softSkill) =>
    MathUtils.percentage(softSkill.totalCount, insightCount)
  );

  const chartData = {
    labels: softSkills,
    datasets: [
      {
        label: keyword,
        data: softSkillsTotalCount,
        backgroundColor: chartColor,
        borderWidth: 0,
        borderRadius: 3,
        borderSkipped: false,
      },
    ],
  };

  return <BarChart data={chartData} displayLegend={displayLegend} />;
};

export default SoftSkillsChart;
