import React from "react";
import InlineEditInputText from "../../common/widgets/InlineEditInputText";
import CandidateProfileInlineEditInput from "./CandidateProfileInlineEditInput";

/**
 * Generic component for all report editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CandidateProfileInlineEditInputText(props) {
  return (
    <CandidateProfileInlineEditInput
      InlineEditInputComponent={InlineEditInputText}
      {...props}
    />
  );
}
