import React from "react";
import useMutationCandidateUpdate from "./useMutationCandidateUpdate";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../utils/StringUtils";

/**
 * Generic component for all candidate editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CandidateProfileInlineEditInput(props) {
  const {
    InlineEditInputComponent,
    candidate,
    viewAs,
    validate,
    placeholder,
    property,
    className,
  } = props;
  const { mutate, loading, errors } = useMutationCandidateUpdate();
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidateProfileInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_profile_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};
    variables["id"] = candidate._id;
    variables[property] = value;
    mutate({ variables }).catch((error) => {
      showErrorModal();
    });
  };

  return (
    <>
      {ErrorModal}
      <div className={["CandidateProfileInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(candidate[property])}
          onSubmit={onSubmit}
          validate={validate}
          viewAs={viewAs}
          loading={loading}
        />
      </div>
    </>
  );
}
