import React from "react";
import { Row, Col } from "react-bootstrap";
import JobSearchResultList from "./JobSearchResultList";
import ActionLink from "../../common/widgets/ActionLink";
import useTranslationLanding from "../useTranslationLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import * as ROUTES from "../../../constants/routes";
import "./ShortListJob.scss";
import useQueryJobPublicSearch from "../search/useQueryJobPublicSearch";
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

export default function ShortListJob() {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "HowItWork");

  const {redirectTo} = useReferrer();

  function onClickViewMoreByCommission(event) {
    event.preventDefault();
    redirectTo({
      pathname: ROUTES.RECRUITER_JOBS,
      search: "?sortby=COMMISSION_DESC",
    });
  }

  function onClickViewMoreLatest(event) {
    event.preventDefault();
    redirectTo({
      pathname: ROUTES.RECRUITER_JOBS,
      search: "?sortby=DATE_POSTED_DESC",
    });
  }

  const pageSize = 3;

  const {getCurrentLanguageCode} = useLanguages();

  // Memoize searchFilters to avoid re-rendering of components that use them on every render of this component
  const searchFilters = React.useMemo(() => ({}), []);

  const {
    loading: mostRewardedJobsLoading,
    errors: mostRewardedJobsErrors,
    data: mostRewardedJobs,
  } = useQueryJobPublicSearch(pageSize, searchFilters, "COMMISSION_DESC", getCurrentLanguageCode());

  const {
    loading: latestJobsLoading,
    errors: latestJobsErrors,
    data: latestJobs,
  } = useQueryJobPublicSearch(pageSize, searchFilters, "DATE_POSTED_DESC", getCurrentLanguageCode());

  return (
    <div className="ShortListJob">
      <Row>
        <Col className="short-list-title-col">
          {t("landing:landing_short_list_title")}
        </Col>
      </Row>
      <Row>
        <Col className="result-list-col">
          <Row className="result-list-title">
            <Col className="result-title-col">
              {t("landing:landing_most_rewarding_offers")}
            </Col>
          </Row>
          <Row>
            <Col>
              <JobSearchResultList
                loading={mostRewardedJobsLoading}
                errors={mostRewardedJobsErrors}
                jobs={mostRewardedJobs}
              />
            </Col>
          </Row>
          <Row className="action-row">
            <Col>
              <ActionLink
                onClick={(event) => onClickViewMoreByCommission(event)}
              >
                {t("landing:landing_view_more_button_label")}
              </ActionLink>
            </Col>
          </Row>
        </Col>
        <Col className="result-list-col">
          <Row className="result-list-title">
            <Col className="result-title-col">
              {t("landing:landing_latest_jobs")}
            </Col>
          </Row>
          <Row>
            <Col>
              <JobSearchResultList
                loading={latestJobsLoading}
                errors={latestJobsErrors}
                jobs={latestJobs}
              />
            </Col>
          </Row>
          <Row className="action-row">
            <Col>
              <ActionLink onClick={(event) => onClickViewMoreLatest(event)}>
                {t("landing:landing_view_more_button_label")}
              </ActionLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
