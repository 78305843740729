import React from "react";
import useTranslationEmployerLanding from "../useTranslationEmployerLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import pushOnline from "../../../img/pushOnline.svg";
import filter from "../../../img/filter.svg";
import payment from "../../../img/payment.svg";
import { Image } from "react-bootstrap";
import "./HowItWorksEmployeeReferral.scss";

export default function HowItWorksEmployeeReferral() {
  const { t, loading: tLoading } = useTranslationEmployerLanding();
  useShowLoader(tLoading, "HowItWorksEmployeeReferral");

  return (
    <div className="HowItWorksEmployeeReferral">
      <Row>
        <Col className="how-it-works-col-container ">
          <Row>
            <Col className="title-col">
              {t("employer-landing:employee_referral_how_it_works_label")}
            </Col>
          </Row>
          <Row>
            <Col className="subtitle-col">
              {t("employer-landing:employee_referral_how_it_works_sub_label")}
            </Col>
          </Row>
          <Row className="how-it-works-col-container">
            <Col className="how-it-works-col">
              <Row>
                <Col className="how-it-works-step-pic-col">
                  <Image className="steps-img" src={pushOnline} />
                </Col>
              </Row>
              <Row>
                <Col className="step-col">
                  {t("employer-landing:landing_how_it_works_step_label", {
                    count: 1,
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="step-description-col">
                    {t(
                      "employer-landing:employee_referral_how_it_works_step_one_label"
                    )}
                  </div>
                  <ul>
                    <li>
                      {t(
                        "employer-landing:employee_referral_how_it_works_step_one_feature_1_text"
                      )}
                    </li>
                    <li>
                      {t(
                        "employer-landing:employee_referral_how_it_works_step_one_feature_2_text"
                      )}
                    </li>
                    <li>
                      {t(
                        "employer-landing:employee_referral_how_it_works_step_one_feature_3_text"
                      )}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col className="how-it-works-col">
              <Row>
                <Col className="how-it-works-step-pic-col">
                  <Image className="steps-img" src={filter} />
                </Col>
              </Row>
              <Row>
                <Col className="step-col">
                  {t("employer-landing:landing_how_it_works_step_label", {
                    count: 2,
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="step-description-col">
                    {t(
                      "employer-landing:employee_referral_how_it_works_step_two_label"
                    )}
                  </div>
                  <ul>
                    <li>
                      {t(
                        "employer-landing:employee_referral_how_it_works_step_two_feature_1_text"
                      )}
                    </li>
                    <li>
                      {t(
                        "employer-landing:employee_referral_how_it_works_step_two_feature_2_text"
                      )}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col className="how-it-works-col">
              <Row>
                <Col className="how-it-works-step-pic-col">
                  <Image className="steps-img" src={payment} />
                </Col>
              </Row>
              <Row>
                <Col className="step-col">
                  {t("employer-landing:landing_how_it_works_step_label", {
                    count: 3,
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="step-description-col">
                    {t(
                      "employer-landing:employee_referral_how_it_works_step_three_label"
                    )}
                  </div>
                  <ul>
                    <li>
                      {t(
                        "employer-landing:employee_referral_how_it_works_step_three_feature_1_text"
                      )}
                    </li>
                    <li>
                      {t(
                        "employer-landing:employee_referral_how_it_works_step_three_feature_2_text"
                      )}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="title-col">
              {t("employer-landing:employee_referral_external_feature_title")}
            </Col>
          </Row>
          <Row>
            <Col className="subtitle-col">
              {t(
                "employer-landing:employee_referral_external_feature_subtitle"
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
