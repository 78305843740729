import React from "react";
import useMutationProfileUpdate from "./useMutationProfileUpdate";
import useTranslationProfile from "../useTranslationProfile";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../utils/StringUtils";

/**
 * Generic component for all report editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProfileInlineEditInput(props) {
  const {
    InlineEditInputComponent,
    profile,
    property,
    className,
    ...otherProps
  } = props;
  const { mutate, loading, errors } = useMutationProfileUpdate();
  const { t, loading: tLoading } = useTranslationProfile();
  useShowLoader(tLoading, "ProfileInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("profile:recruiters_profile_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};
    variables["id"] = profile._id;
    variables[property] = value;
    mutate({ variables }).catch((error) => {
      showErrorModal();
    });
  };

  return (
    <>
      {ErrorModal}
      <div className={["ReportInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          value={StringUtils.nullToEmpty(profile[property])}
          onSubmit={onSubmit}
          loading={loading}
          {...otherProps}
        />
      </div>
    </>
  );
}
