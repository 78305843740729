import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CandidateSearchResult.scss";
import ActionButton from "../../common/widgets/ActionButton";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import DateUtils from "../../../utils/DateUtils";

export default function CandidateSearchResult(props) {
  const { item: candidate, onClose } = props;

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidateSearchResult");

  const onClickCandidate = useCallback(candidateId => {
    onClose(candidateId);
  }, [onClose]);

  return (
    <ActionButton
      as={"div"}
      variant="outline-primary"
      onClick={(event) => onClickCandidate(candidate._id)}
      className={"CandidateSearchResult"}
    >
      <Row>
        <Col className={"name-col"}>{candidate.name}</Col>
        <Col className={"name-col"}>
          {t("candidates:candidates_details_creation_period_days", {
            count: DateUtils.dateDiff(Date.parse(candidate.updatedAt)),
          })}
        </Col>
      </Row>
    </ActionButton>
  );
}
