import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryRecruiterFeedbackByRecruiterId from "./useQueryRecruiterFeedbackByRecruiterId";
import ReactStars from "react-rating-stars-component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./RatingStarsItem.scss";
import TypeUtils from "../../../utils/TypeUtils";

export default function RatingStarsItem(props) {
  const { recruiterId, size } = props;

  const { data, loading, errors } =
    useQueryRecruiterFeedbackByRecruiterId(recruiterId);

  const ratingAverage = !TypeUtils.arrayIsEmpty(data)
    ? data.reduce((accumulator, current) => accumulator + current.rating, 0) /
      data.length
    : 0;

  return (
    <div className = "RatingStarsItem">
      <HorizontalLoader>
        <WaitForData
          loading={loading}
          errors={errors}
          onError={() => <ReportProblemOutlined className="LoadingError" />}
          onLoaded={() => (
            <Row className="starsColContainer">
              <Col className="starsCol">
                <ReactStars
                  edit={false}
                  count={5}
                  value={ratingAverage}
                  size={size}
                />
              </Col>
            </Row>
          )}
        />
      </HorizontalLoader>
    </div>
  );
}
