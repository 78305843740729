import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "applicationPublic";
const QUERY_NAME = "applicationApprovedById";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: String!) {
    ${QUERY_SCOPE} {
      ${QUERY_NAME}(id: $id) {
        _id
        recruiterName
        candidateApprovalStatus
      }
    }
  }
`;

export default function useQueryApplicationApprovedById(id) {
  const variables = { id };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
