import React, {useCallback} from "react";
import FormGroup from "react-bootstrap/esm/FormGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionLink from "../../common/widgets/ActionLink";
import useContentModal from "../../common/modals/useContentModal";
import CandidatesList from "../view/CandidatesList";
import "./CandidateSaveSelectionForm.scss";
import ValidatedInputEmail from "../../common/widgets/ValidatedInputEmail";
import IndicationRequiredFields from "../../common/widgets/IndicationRequiredFields";
import RemainingApplicationsSection from "./RemainingApplicationsSection";

export default function CandidateSaveSelectionForm({t, control, candidate, recruiterId, jobId, onSelectCandidate}) {

  // Modal to search candidates form
  const {
    ContentModal: SearchCandidatesModal,
    show: showSearchCandidatesModal,
    hide: hideSearchCandidatesModal,
  } = useContentModal("SearchCandidatesModal");

  const onClickSearchCandidates = () => {
    showSearchCandidatesModal(getSearchCandidates());
  };

  const onCloseSearchCandidatesModal = useCallback((candidateId) => {
    hideSearchCandidatesModal();
    if (candidateId) {
      onSelectCandidate(candidateId)
    }
  }, [hideSearchCandidatesModal, onSelectCandidate]);

  const getSearchCandidates = () => {
    return (
      <CandidatesList
        onClose={onCloseSearchCandidatesModal}
        recruiterId={recruiterId}
      />
    );
  };

  const recommendInvite =
    <>
      {t("candidates:candidates_save_selection_recommend_invite_msg")}
      <ActionLink
        as={"span"}
        variant="outline-primary"
        onClick={(event) => onClickSearchCandidates(event)}
      >
        <span className="candidates-invite-action-link">
          {t("candidates:candidates_save_selection_select_candidates_invite_msg")}
        </span>
      </ActionLink>
    </>
  ;

  const createInvite = t("candidates:candidates_save_selection_create_invite_msg");

  return (
    <>
      {SearchCandidatesModal}
      <div className="CandidateSaveSelectionForm">
        {jobId && (
          <Row className="application-count-row">
            <Col>
              <RemainingApplicationsSection
                jobId={jobId}
                recruiterId={recruiterId}
              />
            </Col>
          </Row>
        )}
        <Row className="information_invite_row">
          <Col>
            {jobId ? recommendInvite : createInvite}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="input-row">
              <Col className="input-col">
                <FormGroup controlId="email">
                  <ValidatedInputEmail
                    className="FormControl"
                    defaultValue={candidate.email}
                    required
                    name="email"
                    control={control}
                    type="email"
                    placeholder={t(
                      "candidates:candidates_create_candidate_email_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="indication-row">
              <Col className="indication-col">
                <IndicationRequiredFields />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
