import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormControl } from "react-bootstrap";
import "./EnumFilter.scss";
import TypeUtils from "../../../utils/TypeUtils";
import StringUtils from "../../../utils/StringUtils";

export default function EnumFilter(props) {
  const { updateSelectedValue, selectedValue, choices, name, label, className } = props;

  const onChange = (event) => {
    event.preventDefault();
    updateSelectedValue(event.target.value);
  };

  // RENDER
  return (
    <Row className={`EnumFilter ${StringUtils.nullToEmpty(className)}`}>
      <Col className="filter-label">
        {label}
      </Col>
      <Col className="form-control-col">
        <FormControl
          className="form-control-box"
          as={"select"}
          name={name}
          value={selectedValue}
          onChange={onChange}
          custom
        >
          {TypeUtils.ensureArray(choices).map((choice, index) =>
            <option
              key={index}
              className={"option"}
              value={choice.value}
            >
              {choice.label}
            </option>
          )}
        </FormControl>
      </Col>
    </Row>
  );
}
