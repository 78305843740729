import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "emailSubscriptionPublic";
const QUERY_NAME = "emailSubscriptionByEmail";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($email: String!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(email: $email) {
        _id
        canBeRecommended
        canReceiveAlert
      }
    }
  }
`;

export default function useQueryEmailSubscriptionByEmail(email) {
  const variables = { email: email };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
