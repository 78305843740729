import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ApplicationForm.scss";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import ApplicationInlineEditInputWysiwyg from "./ApplicationInlineEditInputWysiwyg";
import ActionButton from "../../common/widgets/ActionButton";
import ActionLink from "../../common/widgets/ActionLink";
import useQueryJobById from "../view/useQueryJobById";
import WaitForData from "../../common/data-fetching/WaitForData";
import Loader from "../../common/loading-widgets/Loader";
import EmployerItem from "../view/EmployerItem";
import useQueryApplicationById from "../view/useQueryApplicationById";
import { MAX_LENGTH_APPLICATION_NOTE } from "../../../constants/ui";
import useLazyQueryApplicationResendApprovalEmailCopy from "../view/useLazyQueryApplicationResendApprovalEmailCopy";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../common/modals/useErrorModal";
import useSuccessModal from "../../common/modals/useSuccessModal";
import CandidateApprovalStatusEnum from "../CandidateApprovalStatusEnum";

export default function ApplicationForm(props) {
  const { applicationId, jobId, onClose } = props;
  const {
    data: application,
    loading: applicationLoading,
    errors: applicationErrors,
  } = useQueryApplicationById(applicationId);
  const { data: job, loading, errors } = useQueryJobById(jobId);
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationForm");

  // Modal to show when an approval email is sent

  const resendEmailSuccessMessage = (
    <p>{t("candidates:candidates_job_reminder_email_msg")}</p>
  );
  const { SuccessModal: successShowModal, show: showSuccessShowModal } =
    useSuccessModal(resendEmailSuccessMessage);

  const { fetch } = useLazyQueryApplicationResendApprovalEmailCopy(
    applicationId
  );

  const onClickResendEmail = () => {
    return fetch({ applicationId })
      .then(showSuccessShowModal())
      .catch((error) => {
        setError(error);
        showErrorModal();
      });
  };

  // error returned by useLazyQueryResumeDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [error, setError] = React.useState(null);
  const errorMsg = useServerErrorFormatter(error);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_resume_download_error")}</p>
      {errorMsg}
    </>
  );

  return (
    <Loader>
      {successShowModal}
      {ErrorModal}
      <WaitForData
        loading={loading || applicationLoading}
        errors={errors || applicationErrors}
        onLoaded={() => (
          <div className="ApplicationForm">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h1>{job.title}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EmployerItem organizationId={job.organizationId} />
                  </Col>
                </Row>
              </Col>
              <Col className="last-col">
                <Row>
                  <Col className="commission-title-col">
                    {t("candidates:candidates_job_details_commission_title")}
                  </Col>
                </Row>
                <Row>
                  <Col className="commission-col">
                    {t("candidates:candidates_job_details_commission_amounts", {
                      amount: job.commission,
                    })}
                  </Col>
                </Row>
                {application.candidateApprovalStatus === CandidateApprovalStatusEnum.PENDING && (
                  <Row>
                    <Col className="col-action">
                      <ActionLink onClick={onClickResendEmail}>
                        {t(
                          "candidates:candidates_application_resend_approval_email_label"
                        )}
                      </ActionLink>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row>
              <Col className="label-row">
                {t("candidates:candidates_application_note_label")}
              </Col>
            </Row>
            <Row>
              <Col className="name-col">
                <ApplicationInlineEditInputWysiwyg
                  application={application}
                  className={"updateNote"}
                  property={"recruiterNote"}
                  placeholder={t(
                    "candidates:candidates_application_note_placeholder"
                  )}
                  required
                  maxLength={MAX_LENGTH_APPLICATION_NOTE}
                />
              </Col>
            </Row>
            <Row>
              <Col className="form-actions">
                <ActionButton onClick={onClose}>
                  {t("candidates:candidates_close_action")}
                </ActionButton>
              </Col>
            </Row>
          </div>
        )}
      />
    </Loader>
  );
}
