import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import AuthUserProvider from "../authentication-no-ui/AuthUserProvider";
import FirebaseProvider from "../infra-no-ui/firebase/FirebaseProvider";
import BasePage from "../common/page/BasePage";
import HeaderPortal from "../common/page/HeaderPortal";
import FooterPortal from "../common/page/FooterPortal";
import TranslationProvider from "../infra-no-ui/translation/TranslationProvider";
import FirebaseAuthProvider from "../authentication-no-ui/FirebaseAuthProvider";
import NotFoundPage from "./NotFoundPage";
import AuthenticatedApolloProvider from "../authentication-no-ui/AuthenticatedApolloProvider";
import MissingProfileInterceptor from "../authentication-ui/MissingProfileInterceptor";
import DisconnectedModal from "../authentication-ui/DisconnectedModal";
import ResetPasswordPage from "../authentication-ui/ResetPasswordPage";
import FirebaseErrorPage from "../authentication-ui/FirebaseErrorPage";
import DatePickerLocaleProvider from "./DatePickerLocalizationProvider";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Loader from "../common/loading-widgets/Loader";
import Menu from "../menu/Menu";
import Footer from "../footer/Footer";
import SignOutPage from "../authentication-ui/SignOutPage";
import SignUpPage from "../authentication-ui/SignUpPage";
import SignInPage from "../authentication-ui/SignInPage";
import DashboardPage from "../dashboard/view/DashboardPage";
import JobsPage from "../jobs/view/JobsPage";
import JobPage from "../jobs/view/JobPage";
import CandidateRecommendPage from "../candidates/mutate/CandidateRecommendPage";
import CandidateProfilePage from "../candidates/view/CandidateProfilePage";
import CandidatesPage from "../candidates/view/CandidatesPage";
import ProfilePage from "../profile/view/ProfilePage";
import LandingPage from "../landing/view/LandingPage";
import { apolloCacheManager } from "./apolloCacheManager";
import useQueryRecruiterByEmail from "../profile/view/useQueryRecruiterByEmail";
import CandidateApprovedApplicationPage from "../jobs/view/CandidateApprovedApplicationPage";
import CandidateRejectedApplicationPage from "../jobs/view/CandidateRejectedApplicationPage";
import ContactUsPage from "../footer/ContactUsPage";
import PrivacyPage from "../footer/PrivacyPage";
import CareersPage from "../footer/CareersPage";
import TermsPage from "../footer/TermsPage";
import EmployerLandingPage from "../employer-landing/view/EmployerLandingPage";
import AboutUsPage from "../footer/AboutUsPage";
import PricingPage from "../footer/PricingPage";
import CandidateUnsubscriptionPage from "../email-subscription/view/CandidateUnsubscriptionPage";
import SpotterUnsubscriptionPage from "../email-subscription/view/SpotterUnsubscriptionPage";
import BlogPage from "../blog/view/BlogPage";
import BlogCategoryPage from "../blog/view/BlogCategoryPage";
import BlogPostPage from "../blog/view/BlogPostPage";
import MenuLogo from "../menu/MenuLogo";
import EmployerMenuLogo from "../menu/EmployerMenuLogo";
import EmployerMenu from "../menu/EmployerMenu";
import HeaderNavbar from "../menu/HeaderNavbar";
import InsightsSearchPage from "../../components/insights/view/InsightsSearchPage";
import InsightsResultPage from "../../components/insights/view/InsightsResultPage";
import InsightsComparisonResultPage from "../../components/insights/view/InsightsComparisonResultPage";
import TranslatedContentError from "../common/error-boundaries/TranslatedContentError";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary";
import DefaultLanguageSwitcher from "../common/page/DefaultLanguageSwitcher";
import ConnectionModePage from "../authentication-ui/ConnectionModePage";
import ConnectionModeSignUpPage from "../authentication-ui/ConnectionModeSignUpPage";
import ApolloErrorLogger from "../common/error-boundaries/ApolloErrorLogger";
import ErrorTestPage from "./ErrorTestPage";
import EmployerPage from "../employers/view/EmployerPage";
import HowItWorksPage from "../landing/view/HowItWorksPage";
import SponsorCodeProvider from "../sponsorship/SponsorCodeProvider";
import SponsorshipPage from "../sponsorship/view/SponsorshipPage";
import SponsoredListPage from "../sponsorship/view/SponsoredListPage";
import SponsoredInvitePage from "../sponsorship/view/SponsoredInvitePage";
import EmployeeReferralLandingPage from "../employer-landing/view/EmployeeReferralLandingPage";
import CandidateCreatePage from "../candidates/mutate/CandidateCreatePage.js";

export default function AppRouter() {
  // Set menu logo depending on the current page
  const menuLogo = (
    <Switch>
      {/* On the employer landing page, display Spotterful Employer logo. */}
      <Route
        exact
        path={ROUTES.RECRUITER_EMPLOYER_LANDING}
        component={EmployerMenuLogo}
      />
      {/* On any other page, display Spotterful regular logo. */}
      <Route component={MenuLogo} />
    </Switch>
  );

  // Set menu depending on the current page
  const menu = (
    <Switch>
      {/* On the employer landing page, display Spotterful Employer menu. */}
      <Route
        exact
        path={ROUTES.RECRUITER_EMPLOYER_LANDING}
        component={EmployerMenu}
      />
      {/* On any other page, display Spotterful regular menu. */}
      <Route component={Menu} />
    </Switch>
  );

  return (
    <Router>
      {/* Router must come before BasePage, because BasePage ultimately renders links */}
      {/* Display BasePage before everything else, so that we can use it to display translation and authentication errors. */}
      {/* BasePage will use portals to display menu and footer, which depend on translation and authentication that are not yet ready */}
      <BasePage header={<HeaderNavbar logo={menuLogo} />}>
        <Loader>
          <TranslationProvider useShowLoader={useShowLoader}>
            <ErrorBoundary component={<TranslatedContentError />}>
              <DatePickerLocaleProvider>
                <FirebaseProvider errorComponent={FirebaseErrorPage}>
                  <FirebaseAuthProvider disconnectedModal={DisconnectedModal}>
                    <AuthenticatedApolloProvider
                      cacheManager={apolloCacheManager()}
                    >
                      {/* At this point we can log frontend errors using Apollo because it is now available */}
                      <ErrorBoundary
                        component={<TranslatedContentError />}
                        logger={ApolloErrorLogger}
                      >
                        <AuthUserProvider
                          useQueryProfile={useQueryRecruiterByEmail}
                        >
                          <SponsorCodeProvider>
                            <DefaultLanguageSwitcher>
                              <HeaderPortal
                                component={
                                  <HeaderNavbar logo={menuLogo} menu={menu} />
                                }
                              />
                              <Switch>
                                {/* Put that before MissingProfileInterceptor because missing profile must not prevent from signing out */}
                                <Route
                                  exact
                                  path={ROUTES.SIGN_OUT}
                                  component={SignOutPage}
                                />
                                <Route>
                                  {/* Don't go deeper if user is authenticated but has no profile */}
                                  <MissingProfileInterceptor>
                                    <Switch>
                                      {/* Try to keep declarations here and in routes.js in the same order, for maintainability */}
                                      <Route
                                        exact
                                        path={ROUTES.CONNECTION_MODE}
                                        component={ConnectionModePage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.CONNECTION_MODE_SIGN_UP}
                                        component={ConnectionModeSignUpPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.SIGN_IN}
                                        component={SignInPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.SIGN_UP}
                                        component={SignUpPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.HOME}
                                        component={LandingPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_DASHBOARD}
                                        component={DashboardPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_JOBS}
                                        component={JobsPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_JOB}
                                        component={JobPage}
                                      />
                                      <Route exact path={ROUTES.INTERNAL_JOB}>
                                        <JobPage internal />
                                      </Route>
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_CANDIDATES}
                                        component={CandidatesPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_CANDIDATE_PROFILE
                                        }
                                        component={CandidateProfilePage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_CANDIDATE_CREATE}
                                        component={CandidateCreatePage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_CANDIDATE_RECOMMEND
                                        }
                                        component={CandidateRecommendPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_CANDIDATE_APPROVED_APPLICATION
                                        }
                                        component={
                                          CandidateApprovedApplicationPage
                                        }
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_CANDIDATE_REJECTED_APPLICATION
                                        }
                                        component={
                                          CandidateRejectedApplicationPage
                                        }
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_PROFILE}
                                        component={ProfilePage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_PRIVACY}
                                        component={PrivacyPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_CAREERS}
                                        component={CareersPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_TERMS}
                                        component={TermsPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_ABOUT_US}
                                        component={AboutUsPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_PRICING}
                                        component={PricingPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.CANDIDATE_EMAIL_UNSUBSCRIPTION
                                        }
                                        component={CandidateUnsubscriptionPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.BLOG}
                                        component={BlogPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.BLOG_CATEGORY}
                                        component={BlogCategoryPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.BLOG_POST}
                                        component={BlogPostPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.HOW_IT_WORKS}
                                        component={HowItWorksPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_EMAIL_UNSUBSCRIPTION
                                        }
                                        component={SpotterUnsubscriptionPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_EMPLOYER_LANDING}
                                        component={EmployerLandingPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_EMPLOYEE_REFERRAL_LANDING
                                        }
                                        component={EmployeeReferralLandingPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_MARKET_INSIGHTS_TOOL
                                        }
                                        component={InsightsSearchPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_MARKET_INSIGHTS}
                                        component={InsightsResultPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.RECRUITER_MARKET_INSIGHTS_COMPARISON
                                        }
                                        component={InsightsComparisonResultPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RESET_PASSWORD}
                                        component={ResetPasswordPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RECRUITER_CONTACT_US}
                                        component={ContactUsPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER}
                                        component={EmployerPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.INTERNAL_EMPLOYER}
                                      >
                                        <EmployerPage isInternal />
                                      </Route>
                                      <Route exact path={ROUTES.SPONSORSHIP}>
                                        <SponsorshipPage />
                                      </Route>
                                      <Route
                                        exact
                                        path={ROUTES.SPONSORSHIP_INVITE}
                                      >
                                        <SponsoredInvitePage />
                                      </Route>
                                      <Route exact path={ROUTES.SPONSORED}>
                                        <SponsoredListPage />
                                      </Route>
                                      {/* Marketing campaigns */}
                                      <Route exact path={ROUTES.C_UMTL}>
                                        <Redirect
                                          to={{
                                            pathname: ROUTES.HOME,
                                            search:
                                              "?utm_source=plv&utm_medium=qr&utm_campaign=umtl",
                                          }}
                                        />
                                      </Route>
                                      <Route exact path={ROUTES.C_USHERB}>
                                        <Redirect
                                          to={{
                                            pathname: ROUTES.HOME,
                                            search:
                                              "?utm_source=plv&utm_medium=qr&utm_campaign=usherb",
                                          }}
                                        />
                                      </Route>
                                      {/* Error test page */}
                                      <Route
                                        exact
                                        path={ROUTES.ERROR_TEST}
                                        component={ErrorTestPage}
                                      />
                                      {/* Catch-all rule when none of the above matches */}
                                      <Route
                                        path="/"
                                        component={NotFoundPage}
                                      />
                                    </Switch>
                                  </MissingProfileInterceptor>
                                </Route>
                              </Switch>
                              <FooterPortal component={<Footer/>} />
                            </DefaultLanguageSwitcher>
                          </SponsorCodeProvider>
                        </AuthUserProvider>
                      </ErrorBoundary>
                    </AuthenticatedApolloProvider>
                  </FirebaseAuthProvider>
                </FirebaseProvider>
              </DatePickerLocaleProvider>
            </ErrorBoundary>
          </TranslationProvider>
        </Loader>
      </BasePage>
    </Router>
  );
}
