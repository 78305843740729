import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../../common/loading-widgets/Loader";
import JobSearchItem from "../../jobs/search/JobSearchItem";
import JobSearchResultList from "../../jobs/search/JobSearchResultList";
import JobSearchPane from "./JobSearchPane";
import "./JobListWithSearchPane.scss";

export default function JobListWithSearchPane(props) {
  const { companyName, initialSearchFilters, initialSortBy, initialPageSize, isInternal } = props;

  const [searchFilters, setSearchFilters] =
    React.useState(initialSearchFilters);
  const [sortValue, updateSortValue] = React.useState(initialSortBy);
  const [pageSizeValue, updatePageSizeValue] = React.useState(initialPageSize);

  const searchResult = React.useMemo(
    () => <JobSearchItem searchFilters={searchFilters} isInternal={isInternal} />,
    [searchFilters, isInternal]
  );

  // RENDER
  return (
    <div className="JobListWithSearchPane">
      <Row>
        <Col>
          <JobSearchPane
            companyName={companyName}
            searchFilters={searchFilters}
            updateSearchFilters={setSearchFilters}
            sortValue={sortValue}
            updateSortValue={updateSortValue}
            pageSizeValue={pageSizeValue}
          />
        </Col>
      </Row>
      <Row className="rowFilterContainer">
        <Col className="JobSearchResultListContainer">
          <Loader>
            <JobSearchResultList
              resultComponent={searchResult}
              searchFilters={searchFilters}
              sortValue={sortValue}
              pageSizeValue={pageSizeValue}
              updatePageSizeValue={updatePageSizeValue}
            />
          </Loader>
        </Col>
      </Row>
    </div>
  );
}
