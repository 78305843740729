import { LinkContainer } from "react-router-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import TypeUtils from "../../utils/TypeUtils";
import { useTranslation } from "react-i18next";
import useLanguages from "../infra-no-ui/translation/useLanguages";
import React from "react";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Nav from "react-bootstrap/Nav";
import useUpdateAuthUserLanguage from "../profile/mutate/useUpdateAuthUserLanguage";
import useReferrer from "../infra-no-ui/navigation/useReferrer";

function MenuItem(props) {

  const { to, external, className, children } = props;

  const {withCurrentAsReferrer} = useReferrer();

  // Wrap children in a Nav.Link component. It is required by the parent Navbar to collapse after select if collapseOnSelect is on.
  // There are other ways, see: https://stackoverflow.com/questions/56464444/collapseonselect-in-react-bootstrap-navbars-with-react-router-dom-navlinks
  // If "external" is set, it means the "to" route is an external url. Use it as the href on the Nav.Link.
  // Otherwise, let React Router handle the "to" route through a LinkContainer.
  // Nav.Link must have an href property in order to work with collapseOnSelect, and LinkContainer will take care of it.

  const navLinkChildren = (
    <Nav.Link
      className="MenuItem-div"
      href={external ? to : undefined}
      target={external ? "_blank" : undefined}
    >
      {children}
    </Nav.Link>
  );
  return external ? (
    <div className={"MenuItem " + className}>
      <div className="children-container">{navLinkChildren}</div>
    </div>
  ) : (
    <div className={"MenuItem " + className}>
      <LinkContainer className="children-container" to={withCurrentAsReferrer(to)} exact>
        {navLinkChildren}
      </LinkContainer>
    </div>
  );
}

function MenuDropdownItem(props) {
  // Wrap an item in a dropdown with Dropdown.Item so that the dropdown closes on click
  return (
    <Dropdown>
      <MenuItem {...props} />
    </Dropdown>
  );
}

function MenuDropdown(props) {
  const { title, items } = props;

  const itemArray = TypeUtils.ensureArray(items);

  if (itemArray.length === 0) return null;

  return (
    <Dropdown className="MenuDropdown">
      <Dropdown.Toggle className="menu-dropdown-toggle" as="div">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>{itemArray}</Dropdown.Menu>
    </Dropdown>
  );
}

function LanguageMenuItem() {
  const updateAuthUserLanguage = useUpdateAuthUserLanguage();

  const onClickLanguage = (event, languageCode) => {
    event.preventDefault();

    // Mark the whole page as loading
    setLoading(true);

    // Tell i18next to fetch the files for the new language
    // Callback is called when other language has been fetched, then it resolves the promise (probably because the whole
    // promise is intended to be thrown and caught by the new Suspense component, but we don't use it yet because
    // using Suspense for loading state is experimental).
    i18n
      .changeLanguage(languageCode, (/*err*/) => {
        // Errors must be caught in the callback, they are not propagated to the promise catch clause
        // But do nothing if an error occurs (such as a missing translation namespace file), let i18next use fallbacks
      })
      .then(() => {
        updateAuthUserLanguage(languageCode)
      })
      .finally(() => {
        // Whether an error occurred or not, stop the suspense that prevents the page from being rendered
        setLoading(false);
      });
  };

  /**
   * Returns the underlying data structure for the language menu. It returns an object with the following properties:
   *  "title": the language object that serves as the title of the language menu
   *  "items": an array of language objects that are the menu subitems
   *
   *  A language object has the following properties:
   *  "name": the 2-letter name of the language, viewable by the user, not to be translated (eg. FR)
   *  "code": the code of the language, used by i18n to retrieve translations (eg. fr)
   *
   *  If there are no other languages than the current language, returns null.
   *  If there is only one other language, returns it as the menu title.
   *  If there are many other languages, returns the current language as the title and all other available languages
   *  as subitems.
   *
   *  @return Underlying data structure as describe above
   */
  const getLanguageMenuStructure = () => {
    // Find current language in the list of defined languages (LANGUAGES).
    // For each language of the resolving chain used by i18n, check if it is in the list of LANGUAGES. Stop as soon as one is
    // found, it means it is the more specific. LANGUAGES[0] will always be somewhere in the resolving chain
    // because it is the fallback language, but it might not be the more specific.
    const currentLanguageCode = getCurrentLanguageCode();
    const currentLanguageName = getLanguageName(currentLanguageCode);

    // Alternate languages to select from
    const otherLanguages = getOtherLanguages();

    // If there is no other language, do not display menu item
    if (otherLanguages.length === 0) return null;

    // If there is only one alternative, display it as the menu item title (clickable), without menu subitems
    if (otherLanguages.length === 1)
      return {
        title: otherLanguages[0],
        items: [],
      };

    // If there are many alternatives, display the current language as the menu item title, then alternatives as subitems
    return {
      title: {
        code: currentLanguageCode,
        name: currentLanguageName,
      },
      items: otherLanguages,
    };
  };

  // We don't call useTranslationMenu because it is not required here to wait for the translations to be loaded.
  // If t() is needed, call useTranslationMenu and watch for the loading property.
  const { i18n } = useTranslation();
  const { getCurrentLanguageCode, getLanguageName, getOtherLanguages } =
    useLanguages();
  const [loading, setLoading] = React.useState(false);
  useShowLoader(loading, "MenuComponents");

  const languageMenuStructure = getLanguageMenuStructure();

  if (!languageMenuStructure) return null;

  // No subitems to display in language menu, only a click to switch to the other language
  if (languageMenuStructure.items.length === 0)
    return (
      <MenuItem
        onClick={(event) =>
          onClickLanguage(event, languageMenuStructure.title.code)
        }
      >
        {languageMenuStructure.title.name}
      </MenuItem>
    );

  // Subitems to display in language menu, display current language as dropdown title and then other languages
  const languageMenuItems = languageMenuStructure.items.map((language) => (
    <MenuDropdownItem
      key={language.code}
      onClick={(event) => onClickLanguage(event, language.code)}
    >
      {language.name}
    </MenuDropdownItem>
  ));

  return (
    <MenuDropdown
      title={languageMenuStructure.title.name}
      items={languageMenuItems}
    />
  );
}

export { MenuItem, MenuDropdownItem, MenuDropdown, LanguageMenuItem };
