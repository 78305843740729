import React from "react";
import EditIcon from "@material-ui/icons/EditSharp";
import StringUtils from "../../../utils/StringUtils";
import "./InlineEditBase.scss";
import ActionIcon from "./ActionIcon";
import ActionDiv from "./ActionDiv";

/**
 * Form control that displays a value as read only until we click the Edit icon next to the label.
 * The value then becomes editable through a component passed as parameter. The component must
 * implements the behaviour for properties placeholder, value, onSubmit, validate, onCancel and format.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditBase(props) {

  const {
    value, // Current value to display or edit
    onSubmit, // Callback to call when value is accepted
    openEditing, // Display the edit component right away (no need to click the edit icon)
    className,
    EditComponent, // Component to display when user clicks the edit icon, to modify the value
    formatValue, // Callback to format the raw value before displaying it
    ViewComponent, // Component to display when in view mode
    multilineView, // Set to true if content should span multiple lines when in view mode
    ...otherProps // Other props to pass thru to edit component and view component
  } = props;

  const [isEditing, setIsEditing] = React.useState(openEditing);

  const onLocalSubmit = value => {
    setIsEditing(false);
    onSubmit(value);
  }

  const formattedValue = formatValue ? formatValue(value) : value;

  const RealViewComponent = ViewComponent ? ViewComponent : DefaultViewComponent;
  const newClassName = ["InlineEditBase", multilineView ? "multiline-view" : "", className].join(" ");

  return (
    <div className={newClassName}>
      {!isEditing &&
      <RealViewComponent
        value={formattedValue}
        onEnterEdit={() => setIsEditing(true)}
        {...otherProps}
      />}
      {isEditing &&
      <div className={"edit"}>
        <EditComponent
          value={value}
          onSubmit={onLocalSubmit}
          onCancel={() => setIsEditing(false)}
          {...otherProps}
        />
      </div>
      }
    </div>
  );
};

function DefaultViewComponent(props) {
  const {value, onEnterEdit, viewAs: ViewAs, loading, actionIcon, className,  placeholder,} = props;

  const onClickEdit = event => {
    event.preventDefault();
    onEnterEdit();
  }

  const hasValue = !StringUtils.isNullOrEmpty(value);
  const displayValue = hasValue ? value : placeholder;

  const valueAs = ViewAs ? <ViewAs>{displayValue}</ViewAs> : displayValue;
  const editIcon = actionIcon ? actionIcon : EditIcon;
  const hasValueClassName = hasValue ? "has-value" : "no-value";

  return (
    <div className={"view"}>
      <div className={["value", hasValueClassName].join(" ")}>
        <ActionDiv  onClick={onClickEdit}>{valueAs}</ActionDiv>
      </div>
      <div className={"action"}>
        <ActionIcon classNameIcon = {"updateIcon"} onClick={onClickEdit} className={className} icon={editIcon} loading={loading}/>
      </div>
    </div>
  );
}
