import DoughnutChart from "./DoughnutChart";
import MathUtils from "../../../utils/MathUtils";

const PopularityComparisonCard = (props) => {
  const {
    firstKeyword,
    secondKeyword,
    firstInsightCount,
    secondInsightCount,
    chartColor,
  } = props;

  const totalInsightCount = firstInsightCount + secondInsightCount;
  const firstInsightCountPercent = MathUtils.percentage(
    firstInsightCount,
    totalInsightCount
  );
  const secondInsightCountPercent = MathUtils.percentage(
    secondInsightCount,
    totalInsightCount
  );

  const chartData = {
    labels: [firstKeyword, secondKeyword],
    datasets: [
      {
        label: "Popularity",
        data: [firstInsightCountPercent, secondInsightCountPercent],
        backgroundColor: chartColor,
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="popularity-comparison-card">
      <DoughnutChart data={chartData} />
    </div>
  );
};

export default PopularityComparisonCard;
