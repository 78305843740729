import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "organization";
const QUERY_NAME = "organizationByNameUrl";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($nameUrl: String!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(nameUrl: $nameUrl) {
        _id
        company
        companyUrl
        companyPresentation
        companyIntroFr
        companyIntroEn
        companyPhone
        companyEmail
        companyFacebookUrl
        companyLinkedinUrl
        companyCoverTagLineFr
        companyCoverTagLineEn
        companyIsVerifiedAccount
        companyLogoUrl
      }
    }
  }
`;

export default function useQueryOrganizationByNameUrl(nameUrl) {
  const variables = { nameUrl };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
