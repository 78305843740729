import { Col, Row, Image } from "react-bootstrap";
import whyImg from "../../../img/insights-landing-why.svg";
import "./WhyNeedInsights.scss";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";

const WhyNeedInsights = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "WhyNeedInsights");

  return (
    <div className="WhyNeedInsights">
      <Row className="why-need-insights-row-container">
        <Col className="why-need-insights-col">
          <Image src={whyImg} />
        </Col>
        <Col className="why-need-insights-col">
          <Row>
            <Col className="title-col">
              {t("insights:insights_why_need_insights_label")}
            </Col>
          </Row>
          <Row>
            <Col className="text-col">
              {t("insights:insights_why_need_insights_text")}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default WhyNeedInsights;
