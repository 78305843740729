import React from "react";
import Helmet from "react-helmet";
import CommonSeo from "./CommonSeo";
import { SEO_CONFIG } from "../../../constants/seo";

export default function JopOpeningSeo(props) {
  const {
    title,
    description,
    datePosted,
    employmentType,
    company,
    jobCity,
    jobState,
    jobCountry,
    url,
    companyUrl,
  } = props;
  const titleToShow = title + " | " + SEO_CONFIG.siteName;
  const descriptionToShow = title + " - " + company + " - " + jobCity;
  const structuredDataJobOpening = `{
          "@context" : "https://schema.org/",
          "@type" : "JobPosting",
          "title" : "${title}",
          "description" : "${description.replace(/\n/g, "\\n")}",
          "datePosted" : "${datePosted}",
          "employmentType" : "${employmentType}",
          "hiringOrganization" : {
            "@type" : "Organization",
            "name" : "${company}",
            "sameAs" : "${companyUrl}"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "${jobCity}",
              "addressRegion": "${jobState}",
              "addressCountry": "${jobCountry}"
            }
          }
        }`;

  return (
    <>
      <CommonSeo url={url} description={descriptionToShow} title={titleToShow} />
      <Helmet>
        {/* Structured data */}
        <script type="application/ld+json">
          {structuredDataJobOpening}
        </script>
      </Helmet>
    </>
  );
};
