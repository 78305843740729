import React from "react";
import Page from "../common/page/Page";
import useTranslationFooter from "./useTranslationFooter";
import Privacy from "./Privacy";


export default function PrivacyPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationFooter();

  return (
    
      <Page className="PrivacyPage">
        <Page.Title loading={tLoading}>
        {t("footer:footer_privacy")}
        </Page.Title>
        <Page.Content>
          <Privacy/>
        </Page.Content>
      </Page>
    
  );
}
