import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import { Col, Row, Form, FormControl, Button } from "react-bootstrap";
import { useState } from "react";
import queryString from "query-string";
import * as ROUTES from "../../../constants/routes";
import "./SearchPane.scss";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

const CompareInsightsPane = (props) => {
  const { firstKeyword, secondKeyword } = props;

  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "CompareInsightsPane");

  const [firstQueryText, setFirstQueryText] = useState(firstKeyword);
  const [secondQueryText, setSecondQueryText] = useState(secondKeyword);

  const {redirectTo} = useReferrer();

  const onClickCompare = (event) => {
    event.preventDefault();
    redirectTo({
      pathname: ROUTES.RECRUITER_MARKET_INSIGHTS_COMPARISON,
      search: queryString.stringify({
        firstKeyword: firstQueryText,
        secondKeyword: secondQueryText,
      }),
    });
  };

  const firstPlaceholder = t("insights:insights_compare_insights_placeholder") + " 1";
  const secondPlaceholder = t("insights:insights_compare_insights_placeholder") + " 2";

  return (
    <Form
      className="CompareInsightsPane"
      onSubmit={(event) => onClickCompare(event)}
    >
      <Row>
        <Col className="form-col-input">
          <FormControl
            type="text"
            placeholder={firstPlaceholder}
            onChange={(event) => setFirstQueryText(event.target.value)}
            className="input"
            defaultValue={firstKeyword || ""}
            required
          />
        </Col>
        <Col className="form-col-input">
          <FormControl
            type="text"
            placeholder={secondPlaceholder}
            onChange={(event) => setSecondQueryText(event.target.value)}
            className="input"
            defaultValue={secondKeyword || ""}
            required
          />
        </Col>
        <Col className="form-col-btn">
          <Button className="btn" type="submit">
            {t("insights:insights_compare_insights_button_label")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CompareInsightsPane;
