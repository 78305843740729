import React from "react";
import Helmet from "react-helmet";
import CommonSeo from "./CommonSeo";

export default function ArticleSeo(props) {
  const {
    title,
    resume,
    author,
    featuredImage,
    datePublished,
    dateModified,
    url,
  } = props;
  const structuredDataArticle = `{
      "@context": "https://schema.org",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "${url}"
      },
      "@type": "NewsArticle",
      "headline": "${title}",
      "image": ["${featuredImage}"],
      "datePublished": "${datePublished}",
      "dateModified": "${dateModified}",
      "author": {
          "@type": "Person",
          "name": "${author}"
        },
      "publisher": {
        "@type": "Organization",
        "name": "Spotterful",
        "logo": {
          "@type": "ImageObject",
          "url": "https://spotterful.com/logo.png"
        }
      }
    }`;

  return (
    <>
      <CommonSeo url={url} description={resume} title={title} />
      <Helmet>
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={featuredImage}
          data-react-helmet="true"
        />
        {/* Structured data */}
        <script type="application/ld+json">{structuredDataArticle}</script>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7101372108443523"
          crossorigin="anonymous"
        ></script>
      </Helmet>
    </>
  );
}
