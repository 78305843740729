import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionLink from "../../common/widgets/ActionLink";
import ActionButton from "../../common/widgets/ActionButton";
import BlogUtils from "./BlogUtils";
import Card from "react-bootstrap/Card";
import CategoryIcon from "@material-ui/icons/Category";
import useTranslationBlog from "../useTranslationBlog";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./BlogPostPreview.scss";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

export default function BlogPostPreview(props) {
  const { article } = props;
  const {redirectTo} = useReferrer();

  const { t, loading: tLoading } = useTranslationBlog();
  useShowLoader(tLoading, "BlogPostPreview");

  const onClickCategory = (category) => {
    redirectTo(BlogUtils.getBlogCategoryRoute(category));
  };


  const onClickTitle = (category, url) => {
    redirectTo(BlogUtils.getBlogPostRoute(category, url));
  };

  return (
    <div className="BlogPostPreview">
      <Row className="post-col-row">
        <Col className="post-col">
          <Card className="post-card">
            <Card.Img
              variant="top"
              src={
                article.fields.featuredImage
                  ? article.fields.featuredImage.fields.file.url
                  : ""
              }
              className="image-post"
            />
            <Card.Body className="post-body">
              <Row>
                <Col className="title-col">
                  <Card.Title>
                    <ActionLink
                      className="title-link"
                      onClick={() =>
                        onClickTitle(
                          article.fields.categoryEntity.fields.categoryUrl,
                          article.fields.url
                        )
                      }
                    >
                      {article.fields.title}
                    </ActionLink>
                  </Card.Title>
                </Col>
              </Row>
              <Row className="post-information">
                <Col>
                  <Row className="post-information-row">
                    <Col className="icon-group">
                      <Row>
                        <Col className="icon-col">
                          <CategoryIcon />
                        </Col>
                        <Col>
                          <ActionLink
                            onClick={() =>
                              onClickCategory(
                                article.fields.categoryEntity.fields.categoryUrl
                              )
                            }
                          >
                            {article.fields.categoryEntity.fields.categoryName}
                          </ActionLink>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>{article.fields.resume}</Col>
              </Row>
              <Row>
                <Col className="action-col">
                  <ActionButton
                    onClick={() =>
                      onClickTitle(
                        article.fields.categoryEntity.fields.categoryUrl,
                        article.fields.url
                      )
                    }
                  >
                    {t("blog:blog_read")}
                  </ActionButton>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
