import React from "react";
import * as ROUTES from "../../constants/routes";
import Nav from "react-bootstrap/Nav";
import "./Menu.scss";
import { MenuItem } from "./MenuComponents";
import useTranslationMenu from "./useTranslationMenu";

export default function EmployerMenu() {
  const { t, loading: tLoading } = useTranslationMenu();

  // Don't use useShowLoader because there is no loader to display over this component; just wait until there is something to display.
  if (tLoading) {
    return null;
  }

  return (
    <Nav className="EmployerMenu Menu">
      <MenuItem to={ROUTES.HOME}>{t("menu:menu_item_for_recruiters")}</MenuItem>
      <MenuItem  to={ROUTES.EMPLOYER_SIGN_UP} external>{t("menu:menu_item_employer_sign_up")}</MenuItem>
      <MenuItem className="menu-item-outlined" to={ROUTES.EMPLOYER_SIGN_IN} external>{t("menu:menu_item_sign_in")}</MenuItem>
    </Nav>
  );
}
