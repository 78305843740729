import React from "react";
import Page from "../../common/page/Page";
import WaitForData from "../../common/data-fetching/WaitForData";
import ProfileBlock from "./ProfileBlock";
import useAuthUser from "../..//authentication-no-ui/useAuthUser";
import useTranslationProfile from "../useTranslationProfile";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import useShowLoader from "../../common/loading-widgets/useShowLoader";

export default function ProfilePage(props) {
  const { profile, loading, error } = useAuthUser();

  const { t, loading: tLoading } = useTranslationProfile();
  useShowLoader(tLoading, "ProfilePage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={loading}
          error={error}
          onLoaded={() => (
            <Page className="ProfilePage">
              <Page.Title loading={tLoading}>
                {t("profile:recruiters_profile_title")}
              </Page.Title>
              <Page.Content>
                <ProfileBlock profile={profile} />
              </Page.Content>
            </Page>
          )}
        />
      )}
    />
  );
}
