import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryOrganizationById from "./useQueryOrganizationById";

export default function EmployerItem(props) {
  const { organizationId } = props;

  const { data: organization, loading, errors } = useQueryOrganizationById(organizationId);

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => organization.company
        }
      />
    </HorizontalLoader>
  );
}
