import React from "react";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";
import TypeUtils from "../../../utils/TypeUtils";
import StringUtils from "../../../utils/StringUtils";

/**
 * Format a server error message so that it can be displayed in a modal.
 * @param errors
 * @param message Introduction message before displaying errors one by one
 * @param showDetails True to display the name and message of the error, false to display only intro message
 * @returns {JSX.Element|null}
 */
export default function useServerErrorFormatter(
  errors,
  message = undefined,
  showDetails = true
) {
  const { t, loading: tLoading } = useTranslationCommon();
  useShowLoader(tLoading, "useServerErrorFormatter");

  // Concatenate the error name and error message, both being optional
  const makeErrorDescription = React.useCallback(error => {
    let description = "";

    if (!StringUtils.isNullOrEmpty(error.name))
      description = description.concat(t("common:server_error_type_label"), ": ", error.name);

    if (!StringUtils.isNullOrEmpty(error.name) && !StringUtils.isNullOrEmpty(error.message))
      description = description.concat(", ");

    if (!StringUtils.isNullOrEmpty(error.message))
      description = description.concat(t("common:server_error_message_label"), ": ", error.message)

    return description;
  }, [t]);

  // Memoize so that the React component returned does not change on every render
  return React.useMemo(() => {
    if (TypeUtils.arrayIsEmpty(errors)) return null;

    const intro = StringUtils.isNullOrEmpty(message)
      ? t("common:server_error")
      : message;

    return (
      <>
        {!StringUtils.isNullOrEmpty(intro) && <p>{intro}</p>}
        {showDetails &&
        errors.map((error, i) => (
          <p key={i}>
            {makeErrorDescription(error)}
          </p>
        ))}
      </>
    );
  }, [errors, message, t, showDetails, makeErrorDescription]);
}
