import React from "react";
import WaitForData from "../../common/data-fetching/WaitForData";
import BlogPostPreview from "./BlogPostPreview";
import PaginationSection from "./PaginationSection";
import StringUtils from "../../../utils/StringUtils";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RouterUtils from "../../../utils/RouterUtils";
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import useTranslationBlog from "../useTranslationBlog";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useContentful from "./useContentful";
import BlogUtils from "./BlogUtils";
import * as ROUTES from "../../../constants/routes";

export default function BlogCategoryBlock() {
  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const category = urlParams.category;

  const { loading, data, errors, getContentEntries } = useContentful();

  const { t, loading: tLoading } = useTranslationBlog();
  useShowLoader(tLoading, "BlogCategoryBlock");

  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  // Show only blog posts in the selected language
  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  const page = StringUtils.nullToEmpty(searchParams.page)
    ? searchParams.page
    : 1;

  const PAGE_SIZE = 5;

  React.useEffect(() => {
    getContentEntries({
      "fields.language": currentLanguageCode,
      "fields.categoryEntity.fields.categoryUrl[match]": category,
      "fields.categoryEntity.sys.contentType.sys.id":
        process.env.REACT_APP_CONTENTFUL_CONTENT_CATEGORY_TYPE,
      locale: BlogUtils.getBlogLocaleCode(currentLanguageCode),
      content_type: process.env.REACT_APP_CONTENTFUL_CONTENT_BLOG_TYPE,
      limit: PAGE_SIZE,
      skip: PAGE_SIZE * (page - 1),
      order: "-fields.date",
    });
  }, [currentLanguageCode, category, getContentEntries, page]);

  const url = RouterUtils.injectParamsInRoute(ROUTES.BLOG_CATEGORY, {
    category,
  });

  return (
    <div>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <>
            {data.total === 0 && t("blog:blog_no_posts_message")}
            {data.total > 0 && (
              <>
                <Row>
                  <Col>
                    {data.items.map((item, index) => (
                      <BlogPostPreview key={index} article={item} />
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PaginationSection
                      active={page}
                      url={url}
                      size={data.total / PAGE_SIZE}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      />
    </div>
  );
}
