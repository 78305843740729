import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CandidateSaveRewardForm.scss";
import StringUtils from "../../../utils/StringUtils";
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import RewardPaymentTerms from "../../jobs/view/RewardPaymentTerms";

export default function CandidateSaveRewardForm({t, job}) {

  const {getCurrentLanguageCode} = useLanguages();

  return (
    <div className="CandidateSaveRewardForm">
      <Row>
        <Col>
          <p className={"title"}>{t("candidates:candidates_review_reward_msg_1")}</p>
          <p>{t("candidates:candidates_review_reward_msg_2", {amount: StringUtils.formatMoney(job.commission, getCurrentLanguageCode())})}</p>
          <RewardPaymentTerms organization={job.organization}/>
        </Col>
      </Row>
    </div>
  );
}
