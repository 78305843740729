import React from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./Footer.scss";
import useTranslationFooter from "./useTranslationFooter";
import { SocialIcon } from "react-social-icons";
import { FooterItem, LanguageFooterItem } from "./FooterComponents";
import {
  LINKEDIN_URL,
  FACEBOOK_URL,
  RECRUITER_PRIVACY,
  RECRUITER_CAREERS,
  RECRUITER_TERMS,
  RECRUITER_CONTACT_US,
  RECRUITER_ABOUT_US,
  RECRUITER_PRICING,
  BLOG,
  RECRUITER_MARKET_INSIGHTS_TOOL,
  JOB_TITLES_EN,
  JOB_TITLES_FR,
  SPONSORSHIP,
  RECRUITER_EMPLOYEE_REFERRAL_LANDING,
} from "../../constants/routes";
import useLanguages from "../infra-no-ui/translation/useLanguages";

export default function Footer() {
  const { t, loading: tLoading } = useTranslationFooter();

  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  // Don't use useShowLoader because there is no loader to display over this component; just wait until there is something to display.
  if (tLoading) {
    return null;
  }

  return (
    <>
      <Nav className="Footer basepage-fade-in">
        <Container className="justify-content-md-center">
          <Row>
            <Col className="FooterItemGroup">
              <p className="FooterHeaderItem">
                {t("footer:footer_item_language_msg")}
              </p>
              <Row className="footerItem-row">
                <Col className="footerItem-col">
                  <LanguageFooterItem className="FooterItem" to="#">
                    {t("footer:footer_item_language_msg")}
                  </LanguageFooterItem>
                </Col>
              </Row>
            </Col>
            <Col className="FooterItemGroup">
              <Row>
                <Col className="footerItem-col">
                  <p className="FooterHeaderItem">
                    {t("footer:footer_item_product_msg")}
                  </p>
                </Col>
              </Row>
              <Row className="footerItem-row">
                <Col className="footerItem-col">
                  <Nav.Item>
                    <FooterItem
                      to={RECRUITER_EMPLOYEE_REFERRAL_LANDING}
                      className="FooterItem"
                    >
                      {t("footer:footer_item_employee_referral_system_msg")}
                    </FooterItem>
                  </Nav.Item>
                </Col>
              </Row>
            </Col>
            <Col className="FooterItemGroup">
              <Row>
                <Col>
                  <p className="FooterHeaderItem">
                    {t("footer:footer_item_resources_msg")}
                  </p>
                </Col>
              </Row>
              <Row className="footerItem-row">
                <Col className="footerItem-col">
                  <Nav.Item>
                    <FooterItem to={BLOG} className="FooterItem">
                      {t("footer:footer_item_blog_msg")}
                    </FooterItem>
                  </Nav.Item>
                  <Nav.Item>
                    <FooterItem to={SPONSORSHIP} className="FooterItem">
                      {t("footer:footer_item_sponsorship_msg")}
                    </FooterItem>
                  </Nav.Item>
                  <Nav.Item>
                    <FooterItem
                      to={RECRUITER_MARKET_INSIGHTS_TOOL}
                      className="FooterItem"
                    >
                      {t("footer:footer_item_market_insights_msg")}
                    </FooterItem>
                  </Nav.Item>
                  <Nav.Item>
                    <FooterItem
                      to={
                        currentLanguageCode === "fr"
                          ? JOB_TITLES_FR
                          : JOB_TITLES_EN
                      }
                      className="FooterItem"
                    >
                      {t("footer:footer_item_job_titles_list_msg")}
                    </FooterItem>
                  </Nav.Item>
                </Col>
              </Row>
            </Col>
            <Col className="FooterItemGroup">
              <Row>
                <Col className="footerItem-col">
                  <p className="FooterHeaderItem">
                    {t("footer:footer_item_company_msg")}
                  </p>
                </Col>
              </Row>
              <Row className="footerItem-row">
                <Col className="footerItem-col">
                  <Nav.Item>
                    <FooterItem to={RECRUITER_ABOUT_US} className="FooterItem">
                      {t("footer:footer_item_about_us_msg")}
                    </FooterItem>
                  </Nav.Item>
                  <Nav.Item>
                    <FooterItem to={RECRUITER_PRICING} className="FooterItem">
                      {t("footer:footer_item_pricing_msg")}
                    </FooterItem>
                  </Nav.Item>
                  <Nav.Item>
                    <FooterItem to={RECRUITER_PRIVACY} className="FooterItem">
                      {t("footer:footer_item_privacy_policy")}
                    </FooterItem>
                  </Nav.Item>
                  <Nav.Item>
                    <FooterItem to={RECRUITER_TERMS} className="FooterItem">
                      {t("footer:footer_item_terms_and_conditions")}
                    </FooterItem>
                  </Nav.Item>
                  <Nav.Item>
                    <FooterItem to={RECRUITER_CAREERS} className="FooterItem">
                      {t("footer:footer_item_careers")}
                    </FooterItem>
                  </Nav.Item>
                  <Row className="footerItem-row">
                    <Col className="footerItem-col">
                      <Nav.Item>
                        <FooterItem
                          to={RECRUITER_CONTACT_US}
                          className="FooterItem"
                        >
                          {t("footer:footer_item_contact_us_msg")}
                        </FooterItem>
                      </Nav.Item>
                      <SocialIcon
                        url={LINKEDIN_URL}
                        className="SocialIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                      <SocialIcon
                        url={FACEBOOK_URL}
                        className="SocialIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Nav>
      <Container className="companySignature">
        <Row>
          <Col className="companyDisclaimers">
            <span className="companyDisclaimers">
              {t("footer:footer_cad_prices_msg")}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="companySignature">
            <span className="companySignature">
              {t("footer:footer_company_name_msg")}
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
}
