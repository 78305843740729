import {useCallback, useMemo} from "react";
import useTranslationCandidates from "../useTranslationCandidates";
import useResumeDropbox from "./useResumeDropbox";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import useMutationCandidateUpdate from "./useMutationCandidateUpdate";
import CandidateSaveResumeForm from "./CandidateSaveResumeForm.js";

export default function useCandidateSaveResumeForm(candidate) {
  const { t, loading: tLoading } = useTranslationCandidates();
  const {
    ResumeDropbox,
    ready: dropboxReady,
    uploading: dropboxUploading,
    errors: dropboxErrors,
  } = useResumeDropbox(candidate, "dropbox");

  const {
    mutate: updateMutate,
    loading: updateLoading,
    errors: updateErrors,
  } = useMutationCandidateUpdate();

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = useCallback(() => {
    return isValid;
  }, [isValid]);

  const submit = useCallback(() => {
    const fields = getValues();
    return updateMutate({
      variables: {
        id: candidate._id,
        linkedin: fields.linkedin.trim(),
        indeed: fields.indeed.trim(),
      },
    });
  }, [candidate, updateMutate, getValues]);

  const errors = updateErrors || dropboxErrors;

  const formProps = useMemo(() => ({
    t,
    control,
    candidate,
    ResumeDropbox
  }), [t, control, candidate, ResumeDropbox]);

  return {
    form: CandidateSaveResumeForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading && dropboxReady,
    submitting: dropboxUploading || updateLoading,
    errors,
  };
}
