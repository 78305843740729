import useQueryInsightTopCities from "./useQueryInsightTopCities";
import WaitForData from "../../common/data-fetching/WaitForData";
import StringUtils from "../../../utils/StringUtils";
import Loader from "../../common/loading-widgets/Loader";
import SkeletonList from "./SkeletonList";

const CitiesCard = (props) => {
  const { keyword, language, title } = props;

  const {
    loading,
    errors,
    data: cities,
  } = useQueryInsightTopCities(keyword, 10, language);

  return (
    <div className="widget-block">
      <Loader icon={<SkeletonList />}>
        <div className="card-title">{title}</div>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <ol>
              {cities.map((city, index) => (
                <li key={index}>
                  {StringUtils.capitalizeFirstWords(city._id)}
                </li>
              ))}
            </ol>
          )}
        />
      </Loader>
    </div>
  );
};

export default CitiesCard;
