import React from "react";
import Page from "../../common/page/Page";
import BlogBlock from "./BlogBlock";

export default function BlogPage(props) {
  return (
    <Page className="BlogPage">
      <Page.Content className="spaced-children">
        <BlogBlock />
      </Page.Content>
    </Page>
  );
}
