import React from "react";
import * as ROUTES from "../../../constants/routes";
import ActionButton from "../../common/widgets/ActionButton";
import useAuthUser from "../../authentication-no-ui/useAuthUser";
import useTranslationLanding from "../useTranslationLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useReferrer from "../../infra-no-ui/navigation/useReferrer";

export default function BecomeSpotterButton(props) {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "BecomeSpotterButton");

  const {redirectTo} = useReferrer();

  function onClickBecomeSpotter(event) {
    event.preventDefault();
    redirectTo(ROUTES.SIGN_UP);
  }

  function onClickSearchJobs(event) {
    event.preventDefault();
    redirectTo(ROUTES.RECRUITER_JOBS);
  }

  const authUser = useAuthUser();
  const userIsAuthenticated = authUser && authUser.authenticated;

  return (
    <>
      {userIsAuthenticated ? (
        <ActionButton
          className="action-button"
          onClick={(event) => onClickSearchJobs(event)}
        >
          {t("landing:landing_action_button_view-jobs-label")}
        </ActionButton>
      ) : (
        <ActionButton
          className="action-button"
          onClick={(event) => onClickBecomeSpotter(event)}
        >
          {t("landing:landing_action_button_label")}
        </ActionButton>
      )}
    </>
  );
}
