import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationEmailSubscription from "../useTranslationEmailSubscription";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ActionButton from "../../common/widgets/ActionButton";
import useMutationEmailSubscriptionUpdate from "../mutate/useMutationEmailSubscriptionUpdate";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../common/modals/useErrorModal";
import useQueryEmailSubscriptionByEmail from "./useQueryEmailSubscriptionByEmail";
import WaitForData from "../../common/data-fetching/WaitForData";
import "./CandidateUnsubscriptionBlock.scss";

function CandidateUnsubscriptionBlock(props) {
  const { email } = props;
  const {
    data: emailSubscriptionData,
    loading: emailSubscriptionLoading,
    errors: emailSubscriptionErrors,
  } = useQueryEmailSubscriptionByEmail(email);

  const { t, loading: tLoading } = useTranslationEmailSubscription();
  useShowLoader(tLoading, "CandidateUnsubscriptionBlock");

  const { mutate, loading, errors } = useMutationEmailSubscriptionUpdate();
  const errorMsg = useServerErrorFormatter(errors);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("email-subscription:email_subscription_error_message")}</p>
      {errorMsg}
    </>
  );

  const updateSubscription = (canBeRecommended) => {
    const variables = {};
    variables["email"] = email;
    variables["canBeRecommended"] = canBeRecommended;
    mutate({ variables }).catch(() => {
      showErrorModal();
    });
  };

  return (
    <>
      {ErrorModal}
      <WaitForData
        loading={emailSubscriptionLoading}
        errors={emailSubscriptionErrors}
        onLoaded={() => (
          <div className="CandidateUnsubscriptionBlock">
            <Row>
              <Col>
                <h1>
                  {t("email-subscription:email_unsubscription_candidate_title")}
                </h1>
              </Col>
            </Row>
            {(!emailSubscriptionData ||
              emailSubscriptionData.canBeRecommended) && (
              <>
                <Row>
                  <Col>
                    {t(
                      "email-subscription:email_unsubscription_candidate_text"
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ActionButton
                      onClick={() => updateSubscription(false)}
                      loading={loading}
                    >
                      {t(
                        "email-subscription:email_unsubscription_button_label"
                      )}
                    </ActionButton>
                  </Col>
                </Row>
              </>
            )}
            {emailSubscriptionData && !emailSubscriptionData.canBeRecommended && (
              <>
                <Row>
                  <Col>
                    {t("email-subscription:email_subscription_candidate_text")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ActionButton
                      onClick={() => updateSubscription(true)}
                      loading={loading}
                    >
                      {t("email-subscription:email_subscription_button_label")}
                    </ActionButton>
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}
      />
    </>
  );
}

export default CandidateUnsubscriptionBlock;
